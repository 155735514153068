@import "../../stylesheets/modules/modules";

.data-card{
  background: $color-white;
  box-shadow: to-rem(1px) to-rem(3px) to-rem(8px) rgba(0, 0, 0, 0.02);
  margin: 0 0 32px;
  padding: 32px;

  &.no-padding{
    padding: 0;
  }

  &_title{
    margin: 0 0 24px;
  }
}