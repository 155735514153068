@import "../../stylesheets/modules/modules";

.sidebar {
	background: $color-primary;
	border-right: 1px solid rgba(201, 206, 217, 0.95);
	left: 0;
	height: calc(100% - 150px);
	overflow-y: scroll;
	padding: to-rem(40px) to-rem(24px);
	position: fixed;
	top: 150px;
	width: 302px;
	z-index: 1;

	&.no-padding {
		padding: 0;
	}

	&-header {
		margin-bottom: to-rem(30px);
	}

	select {
		background: $color-white url("../../static/icons/caret-down.svg") center right to-rem(15px) no-repeat;
		border: 1px solid rgba(201, 206, 217, 0.95);
		box-sizing: border-box;
		border-radius: 2px;
		height: to-rem(35px);
		margin-top: to-rem(11px);
		outline: none;
		padding: 0 to-rem(15px);
		width: 100%;

		&::placeholder {
			color: $originate-deep-blue;
			opacity: 0.5;
		}
	}

	input {
		background: $color-white;
		border: 1px solid rgba(201, 206, 217, 0.95);
		box-sizing: border-box;
		border-radius: 2px;
		height: to-rem(35px);
		margin-top: to-rem(11px);
		outline: none;
		padding: 0 to-rem(15px);
		width: 100%;

		&::placeholder {
			color: $originate-deep-blue;
			opacity: 0.5;
		}
	}

	&__label {
		cursor: pointer;
		display: block;
		font-size: 14px;
		font-weight: 600;
		line-height: 16px;
		overflow: hidden;
		margin: 0 0 1.2rem;
	}

	.filter-range {
		background: $color-white url("../../static/icons/arrow-right.svg") center to-rem(15px) no-repeat;
		border: 1px solid #dddddd;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: to-rem(25px);
		padding: to-rem(6px) to-rem(10px);
		width: 100%;

		.filter-input,
		input {
			margin-top: 0;
			height: to-rem(25px);

			&:first-of-type {
				margin-left: to-rem(20px);
				max-width: to-rem(75px);
				width: 100%;
			}

			&:last-of-type {
				margin-right: to-rem(20px);
				max-width: to-rem(75px);
				width: 100%;
			}
		}

		.react-datepicker-wrapper {
			width: 100px;

			&:first-of-type {
				input {
					margin-left: to-rem(14px);
				}
			}

			&:last-of-type {
				input {
					margin-left: to-rem(14px);
				}
			}
		}

		input {
			background: none;
			border-radius: 0;
			border: none;
			padding: 0;
		}
	}

	.button {
		font-size: 12px;
		font-weight: $font-regular;
		padding: to-rem(4px) to-rem(32px);
	}
}
