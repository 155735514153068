@import "../../stylesheets/modules/modules";

.copyToClipboard {
  display: flex;
  justify-content: space-between;
  width: to-rem(535px);

  svg {
    margin-left: to-rem(-45px);
  }
}