@import "../../../stylesheets/modules/modules";

input {
	background: $color-background-grey;
	border: 1px solid $border-color;
	border-radius: to-rem(4px);
	box-shadow: none;
	box-sizing: border-box;
	display: block;
	line-height: 24px;
	outline: none;
	padding: to-rem(12px) to-rem(16px);
	width: 100%;

	&[disabled] {
		background: rgba(242, 242, 242, 0.8) no-repeat;
		cursor: not-allowed;
	}
}

.input {
	@extend input;

	&_label {
		cursor: pointer;
		display: block;
		font-size: 14px;
		font-weight: $font-semibold;
		line-height: 16px;
		overflow: hidden;
		margin: 0 0 to-rem(12px);
	}

	&_group {
		display: block;
		margin: 0 0 to-rem(20px);

		.not-allowed {
			cursor: not-allowed;
		}
	}

	&__error {
		color: $color-system-fail;
		display: block;
		font-size: to-rem(11px);
		margin: 0;

		&:empty {
			display: none;
		}
	}
}
