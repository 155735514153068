@import "../../stylesheets/modules/modules";

.datefilter{
  background: $color-white;
  border-radius: to-rem(6px);
  box-shadow: 0 to-rem(5px) to-rem(18.3934px) rgba(0, 0, 0, 0.2);
  padding: to-rem(20px);
  position: absolute;
  margin: to-rem(-20px) 0 0 to-rem(-70px);
  min-width: 200px;
  width: fit-content;
  z-index: 1;

  &__block-list{
    color: $dark-grey;
    font-weight: $font-regular;
    margin: 0;
    padding: 0 0 to-rem(5px) 0;

    li{
      display: block;
      line-height: to-rem(20px);
      padding: 5px 0;
      width: 100%;
    }
  }
  &__top{
    border-bottom: to-rem(1px) solid $dark-grey;
    letter-spacing: 0;

    h5{
      color: $dark-grey;
    }

    h6{
      color: rgba(109, 115, 129, 0.5);
      font-size: to-rem(12px);
      font-weight: $default-font;
      margin-bottom: 0;
      text-transform: capitalize;
    }
  }

  button{
    font-size: 12px;
  }
}

.dd-wrapper{
  color: $color-white;
  display: inline-block;
  float: right;
  font-size: to-rem(13px);
  font-weight: $font-semibold;
  margin-right: to-rem(30px);
  position: relative;
}