@import "../../stylesheets/modules/modules";

.policycard{
  background: $color-white;
  border: to-rem(1px) solid $color-white;
  border-top: to-rem(5px) solid $originate-blue;
  border-radius: to-rem(3px);
  box-shadow: 0 0 to-rem(5px) rgba(232, 232, 232, 0.533854);
  margin: to-rem(60px) 0;
  padding: to-rem(50px) to-rem(30px);

  .button{
    border-radius: to-rem(3px);
    padding: to-rem(10px) to-rem(50px);
  }
}