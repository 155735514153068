@import "../../stylesheets/modules/modules";

.header{
  align-items: center;
  background: $color-white;
  display: flex;
  height: to-rem(70px);
  padding: 0 to-rem(32px);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 9;

  .logo{
    float: left;
    width: to-rem(120px);
  }
}
