@import "../../../stylesheets/modules/modules";

.checkbox{
  border-radius: 3px;
  box-sizing: border-box;
  color: $dark-grey;
  cursor: pointer;
  display: flex;
  line-height: to-rem(24px);
  outline: none;
  width: 100%;

  input, .input{
    appearance: none;
    background: linear-gradient(0deg, #F2F4F8 0%, #FEFFFF 100%);
    border: 1px solid #CED0DA;
    display: inline-block;
    float: left;
    height: 16px;
    position: relative;
    transition: 0.3s;
    width: 16px;

    &::before{
      content: "";
      display: block;
      height: 16px;
      width: 16px;
      box-sizing: border-box;
    }

    &::after{
      content: "";
      height: 5px;
      left: 3px;
      border: solid transparent;
      border-width: 0 0 2px 2px;
      position: absolute;
      top: 4px;
      transform: rotate(-45deg);
      width: 8px;
      box-sizing: border-box;
    }

    &:checked{
      border-color: $originate-blue;
      background: $originate-blue;

      &::after{
        border-color: $color-white;
      }
    }
  }

  &__label{
    margin-left: 15px;
    width: 100%;
  }

  &:disabled,
  &__disabled{
    cursor: not-allowed;
    opacity: 0.4;
  }
}
