@import "../../../stylesheets/modules/modules";

.checkbox-group {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: default;

  label {
    cursor: pointer;
  }
}