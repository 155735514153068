//COLORS//

$originate-blue: #0077ff;
$originate-deep-blue: #06192f;
$color-white: #ffffff;
$color-off-white: #f2f2f2;
$color-black: #000000;
$lighter-border-color: rgba(201, 206, 217, 0.95);
$color-grey: #a4a59a;
$color-background-grey: #f4f5f6;
$border-color: #d7d7d7;
$color-system-fail: #ae4036;
$color-light-background-blue: #F6FBFF;
$color-system-success: #219653;
$color-system-pending: #cc9e58;
$color-status-success: #6fcf97;
$color-status-pending: #2d9cdb;
$color-status-abandoned: #aa57eb;
$color-status-under-review: #f2af01;
$color-status-disbursing: #2d9cdb;
$red-ff3: #ff3838;
$red-c12: #C1292E;
$color-status-awaiting-feedback:rgba(0, 119, 255, 0.2);
$color-status-rejected: #eb5757;
$color-status-awaiting: #2d9cdb;
$color-primary: #fbfbfb;
$disabled: #d3d4d8;
$text-color: #06192f;
$light-blue: #f3f7fc;
$dark-blue: #1b253a;
$blue: #4285f4;
$dark-grey: #6d7381;
$grey-8a8: #8a8a8a;
$color-green: #219653;
$grey-fcf: #fcfcfc;
$grey-eae: #eaeaea;
$grey-efe: #efefef;
$grey-f8f: #f8f8f8;
$grey-202: #202020;
$blue-f1f: #F1F4F8;
$grey-f1f: #F1F4F8;
$grey-6ef: #6E798F;
$green-27a :  #27ae60;

//FONT WEIGHT//
$font-regular: 400;
$font-semibold: 600;
$font-bold: bold;

//FONT FAMILY//
$default-font: "Proxima Nova", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica", "Arial",
	"sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

//  BREAKPOINTS  //
$sm-mobile-width: 425px;
$mobile-width: 767px;
$tablet-width: 979px;
$medium-width: 1199px;
