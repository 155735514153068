@import "../../stylesheets/modules/modules";

.toggle-visibility {
  display: flex;
  justify-content: space-between;
  width: to-rem(535px);
  cursor: pointer;

  svg {
    margin-left: to-rem(-45px);
  }

  &__sub-text {
    color: $originate-blue;
    cursor: pointer;
    display: block;
    font-size: to-rem(12px);
    font-weight: $font-semibold;
    margin: to-rem(-15px) 0 to-rem(15px);
  }
}

.copy-icon {
  cursor: pointer;

  div {
    margin-left: to-rem(-65px);
    margin-top: to-rem(6px);
  }

  svg {
    margin-left: to-rem(-85px);
  }
}