@import "../../stylesheets/modules/modules";

.login {
  &_page {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    width: 100%;

    h5{
      margin: 0 0 to-rem(15px);
    }
  }

  &_logo{
    margin: 0 0 to-rem(45px);

    svg{
      float: left;
    }
  }

  &_holder{
    background: $color-white;
    border: 1px solid rgba($color-black, 0.07);;
    border-radius: 4px;
    max-width: 100%;
    padding: to-rem(40px) to-rem(40px);
    width: 450px;
  }

  &_icon-holder{
    margin: 45px 0;
    text-align: center;
  }

  &_link{
    margin: to-rem(15px) 0 0;
    text-align: center;

    a{
      color: $originate-blue;
      font-size: to-rem(13px);
      font-weight: $font-semibold;
      transition: 0.3s;

      &:hover{
        color: $originate-blue;
        text-decoration: underline;
      }
    }
  }
}