@import "../../../stylesheets/modules/modules";

.header-navs{
  color: $originate-deep-blue;
  font-size: to-rem(14px);
  margin: 0 0 0 to-rem(100px);

  a, button.reports{
    background: transparent;
    border-bottom: to-rem(3px) solid transparent;
    box-sizing: border-box;
    font-size: to-rem(14px);
    font-weight: $font-semibold;
    line-height: 21px;
    letter-spacing: to-rem(0.1px);
    margin-right: to-rem(32px);
    margin-top: 23px;
    padding-bottom: 23px;

    &.active{
      border-color: $originate-blue;
    }
  }

  svg {
    margin-bottom: 5px;
    vertical-align: text-bottom;
    fill: $color-black;
  }

  button{
    border:none;
    cursor: pointer;
  }

  .reports-dropdown-wrapper {
    position: relative;
    display: inline-block;

    &:hover {
      .reports-dropdown {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .guides-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    
    svg {
      margin: 0;
      height: 20px;
      width: 20px;
    }
  }

  .reports-dropdown {
    background: $color-white;
    border-radius: to-rem(6px);
    box-shadow: 0 to-rem(5px) to-rem(18.3934px) rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 2px;
    opacity: 0;
    visibility: hidden;
    padding: to-rem(20px);
    position: absolute;
    transition: 0.3s linear;
    width: 220px;

    a {
      color: $originate-deep-blue;
      border: none;
      padding: 5px 10px;
      line-height: 30px;
      margin: 0;

      &:hover {
        background: rgba($originate-blue, 0.05);
      }

      &.active {
            background: rgba($originate-blue, 0.1);
      }
    }
  }
}