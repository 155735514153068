html,
body {
  background: $color-background-grey;
  font-size: 62.5%;
  font-weight: $font-regular;
  min-height: 100%;
}

body,
input,
textarea,
select,
button {
  color: $text-color;
  font-family: $default-font;
  font-weight: $font-regular;
  font-size: to-rem(14px);
  line-height: to-rem(24px);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
a:focus,
a:hover {
  color: inherit;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -moz-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

img {
  max-width: 100%;
}

.container-fluid {
  width: 100%;
  max-width: none;
}

::-moz-selection {
  background: $originate-blue;
  color: #ffffff;
  opacity: 1;
  text-shadow: none;
}

::selection {
  background: $originate-blue;
  color: $color-white;
  opacity: 1;
  text-shadow: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

h1 {
  font-size: to-rem(44px);
  font-weight: $font-semibold;
  line-height: to-rem(64px);
}

h2 {
  font-size: to-rem(32px);
  font-weight: $font-semibold;
  line-height: to-rem(48px);
}

h3 {
  font-size: to-rem(21px);
  font-weight: $font-semibold;
  line-height: to-rem(26px);
}

h4 {
  font-size: to-rem(18px);
  font-weight: $font-semibold;
  line-height: to-rem(24px);
}

h5 {
  font-size: to-rem(16px);
  font-weight: $font-semibold;
  line-height: to-rem(24px);
}

h6 {
  font-size: to-rem(14px);
  font-weight: $font-semibold;
  line-height: to-rem(24px);
  text-transform: uppercase;
}

span {
  &.checked-width {
    max-width: to-rem(95px);
    min-width: to-rem(60px);

    .fixed {
      margin: 0 auto;
    }
  }

  &.width-sm {
    max-width: to-rem(50px);
    min-width: to-rem(30px);
  }
}

.center-text {
  text-align: center;
}

.left-text {
  text-align: left;
}

.right-text {
  text-align: right;
}

.font-regular {
  font-weight: $font-regular;
}

.font-semibold {
  font-weight: $font-semibold;
}

.font-bold {
  font-weight: $font-bold;
}

.capitalize {
  text-transform: capitalize;
}

.ellipsis {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.row {
  margin: 0 0 48px;

  &_title {
    color: $color-black;
  }
}

.rwt__tablist:not([aria-orientation='vertical']) .rwt__tab[aria-selected='true']:after {
  border-bottom: none !important;
}

.rwt__tab {
  color: $originate-deep-blue;
  cursor: pointer;
  font-size: to-rem(14px) !important;
  font-weight: $font-semibold;
  padding: to-rem(14px) to-rem(20px) !important;
}

.rwt__tab[aria-selected='true'] {
  border-top: 3px solid $originate-blue !important;
  color: $originate-deep-blue;
  position: relative;
}

.rwt__tab[aria-selected='false']:hover,
.rwt__tab:focus {
  outline: 0;
  background: transparent !important;
}

.rwt__tab[aria-selected='true']:hover,
.rwt__tab:focus {
  outline: 0;
  background: linear-gradient(180deg, #f7f8fa 0%, rgba(242, 251, 254, 0) 100%) !important;
}

.rwt__tablist:not([aria-orientation='vertical']) {
  background: $color-white;
  border-bottom: 1px solid #eaeaea !important;
  padding: 0 to-rem(10px) 0 to-rem(10px);
}

.rwt__tab[aria-selected='true'] {
  position: static !important;
}

.react-datepicker {
  margin-left: to-rem(-25px);
}

.react-datepicker-popper[data-placement^='bottom'] {
  margin-left: to-rem(-15px);
}

.status-table {
  width: 62px;
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: baseline;
}

.space-between {
  justify-content: space-between;
}

.tooltip {
  display: inline-block;

  &:hover {
    cursor: help;
    content: '';
  }

  &:hover:after {
    background: #6d7381;
    background: rgba(0, 0, 0, 0.8);
    border-radius: to-rem(5px);
    bottom: -23px;
    color: white;
    content: attr(data-title);
    font-size: to-rem(11px);
    left: 0;
    padding: to-rem(5px) to-rem(15px);
    position: absolute;
    z-index: 98;
  }

  &:hover:before {
    border: solid;
    border-color: #333 transparent;
    border-width: 0 6px 6px 6px;
    bottom: 4px;
    content: '';
    left: 30px;
    position: absolute;
    z-index: 99;
  }
}

span.badge.simple {
  border: none;
  border-radius: 5px;
  color: $color-white;
  font-size: 85%;
  font-weight: $font-semibold;
  margin: 0;
  padding: 5px 10px;

  &.error {
    background: $color-system-fail;
  }

  &.warning {
    background: $color-system-pending;
  }

  &.info {
    background: $originate-blue;
  }
}
