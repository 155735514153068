@import "../../stylesheets/modules/modules";

.settings {
  &.form-card {
    max-width: to-rem(1200px);
    margin: 0 auto;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;

    @include sm-phone {
      flex-direction: column;
      align-items: center;
      max-width: $mobile-width;
    }
  }

  &__card {
    width: 100%;
    height: 198px;
    min-width: to-rem(250px);
    max-width: to-rem(258px);
    font-style: normal;
    font-size: to-rem(15px);
    background: $color-white;
    box-shadow: 0 0 to-rem(4.24219px) rgba(232, 232, 232, 0.533854);
    border-radius: to-rem(6px);
    margin: 0 to-rem(40px) to-rem(40px) 0;
    padding: to-rem(36px) to-rem(27px);

    &:last-of-type {
      margin-right: 0;
    }

    &:hover {
      box-shadow: 0 0 to-rem(4.24219px) rgba(164, 164, 164, 0.533854);
    }

    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      text-transform: capitalize;
      color: $text-color;
      margin: to-rem(14px) 0 to-rem(9px) 0;
    }

    .details {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $text-color;
      opacity: 0.9;
    }
  }
}



@include phone {
  .settings {
    margin: 0 to-rem(15px) 0 to-rem(15px);

    &__wrapper {
      flex-direction: column;
      align-items: center;
      max-width: $mobile-width;
    }

    &__card {
      margin: 0 0 to-rem(15px) 0;
    }
  }
}