@import "../../stylesheets/modules/modules";

.kyc-unverified {
  display: inline-flex;
  margin: 0 10px;
  gap: 10px;
  align-items: center;
  border: 1px solid #ae4036;
  padding: 0 16px;
  border-radius: 24px;
  cursor: pointer;
  background: rgba(174, 64, 54, 0.1);
  color: #ae4036;
  position: relative;

  &__toggle {
    align-items: center;
    display: flex;
    gap: 4px;

    a {
      align-items: center !important;
      font-weight: $font-semibold;
      display: inline-flex !important;
      gap: 4px;
    }
  }

  &__hover {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 275px;
    top: 30px;
    transform: translateX(-50%);
    left: 50%;
    background: white;
    padding: 10px 16px;
    border-radius: 4px;
    box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.2);
    color: #06192f;
    transition: 0.3s linear;
    text-align: center;

    &__link {
      display: inline-flex;
    }

    a {
      align-items: center !important;
      font-weight: $font-semibold;
      display: inline-flex !important;
      gap: 4px;
      text-decoration: underline;
    }
  }

  &:hover {
    .kyc-unverified__hover {
      opacity: 1;
      visibility: visible;
    }
  }
}