@import "../../stylesheets/modules/modules";

.breadcrumbsection{
  background: linear-gradient(0deg, #FBFBFB 100%, rgba(201, 206, 217, 0.95) 47.65%, rgba(251, 251, 251, 0) -376.25%);
  border: 1px solid rgba(201, 206, 217, 0.5);
  font-weight: $font-semibold;
  height: to-rem(80px);
  margin-bottom: to-rem(20px);
  margin-left: 0;
  padding: 28px 30px;
  position: sticky;
  top: 70px;
  z-index: 3;

  &__content{
    float: right;
    padding-right: to-rem(20px);
  }

  .button{
    margin-top: to-rem(-10px);
    margin-left: to-rem(12px);
  }

  .icon{
    margin-right: 10px;
  }
}
