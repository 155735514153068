@import "../../stylesheets/modules/modules";

.invitation {
  display: flex;
  justify-content: center;
  align-items: center;

 .invitation_main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $color-white;
    border: 1px solid rgba($color-black, 0.07);;
    border-radius: 4px;
    max-width: 100%;
    width: 604px;
    height: 801px;
    margin: 73px 418px;
    padding: 31px 44px 37px 44px;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
  
      .invite-title {
        margin-top: 50px;
        margin-bottom: 30px;
      }

      .subtext-container{
        width: 516px;
        height: 102px;
        background: $color-light-background-blue;
        border-radius: 3px;
        padding: 12px 0 12px 12px;
        margin-bottom: 21px;  
 
        .invite-subtext{
          text-align: left;
        }
 
      }
      
      .input {
        width: 516px;
        height: 48px;
        margin-bottom: 30px;
      }
    
      .names {
        display: flex;
        .input {
          width: 251px;
          height: 48px;
          margin-right: 14px;
          margin-bottom: 10px;
        }
      }
        
      .invite-button{
        display: flex;
        justify-content: center;
        align-items: center;
  
        .button {
          margin-top: 43px;
          width: 380px;
          height: 50px;
        }
      }
  
      .invitation_link {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        text-align: center;
      
        a{
          color: $originate-blue;
          font-size: to-rem(13px);
          font-weight: $font-semibold;
          transition: 0.3s;
          margin-left: 8px;
          padding-bottom: 27px;
      
          &:hover{
            color: $originate-blue;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .inivitee-email {
    color: $originate-blue;
  }

  .input {
    &[disabled]{
      background: rgba(242, 242, 242, 0.8) no-repeat;
      cursor: not-allowed;
    }
  }
}
  
  