@import "../../stylesheets/modules/modules";

.component {
  width: 300px;

  @media (min-width: 768px) {
    width: 600px;
  }

  @media (min-width: 1280px) {
    width: 800px;
  }
}

.layout {
  &-customerdetails {
    margin-left: to-rem(375px);
    padding: 0 to-rem(25px);

    .button-export {
      font-weight: $font-regular;
      margin-top: to-rem(-120px);

      .export-csv {
        margin-left: to-rem(10px);
      }

      svg {
        vertical-align: middle;
      }
    }

    &--header {
      padding: to-rem(25px) 0 to-rem(20px) 0;

      .dropdown {
        margin: 0;
      }

      .dd-wrapper {
        color: $dark-blue;
        float: left;
      }

      .customer-dd {
        font-size: to-rem(18px);
        margin-right: to-rem(7px);
      }

      h5 {
        color: $text-color;
        display: inline-block;
        margin: 0;
      }
    }

    .custom--form {
      padding: to-rem(60px) to-rem(52px);
      padding-top: 1rem;
      table {
        border-color: #f2f2f2;
        border-width: to-rem(1px) 0;
        border-style: solid;
        border-collapse: collapse;
      }
      h6 {
        font-size: 1.2rem;
        margin: 0;
      }

      ul {
        margin: 0 0 3.5rem
      }
      span {
        padding: to-rem(10px);
        text-align: left;
        white-space: normal;
      }

      .right-text {
        text-align: right;
      }

      .pending-offer-letter {
        color: $color-system-fail;
      }

    }

    .information--content {
      padding: to-rem(60px) to-rem(52px);

      table {
        border-color: #f2f2f2;
        border-width: to-rem(1px) 0;
        border-style: solid;
        border-collapse: collapse;
      }

      li:first-child {
        border-width: to-rem(4px) 0 0 0;
        border-color: #f2f2f2;
        border-style: solid;
      }

      h6 {
        font-size: 1.2rem;
        margin: 0;
      }

      ul {
        margin: 0 0 3.5rem
      }
      span {
        padding: to-rem(10px);
        text-align: left;
      }

      .right-text {
        text-align: right;
      }

      .pending-offer-letter {
        color: $color-system-fail;
      }

      .button {
        font-weight: $font-regular;
        margin: to-rem(-55px) 0 to-rem(13px) 0;
        padding: to-rem(10px);

        .export-csv {
          margin-left: to-rem(10px);
        }

        svg {
          vertical-align: text-bottom;
        }
      }

      .edit-repayment-date {
        background: inherit;
        font-weight: $font-bold;
      }
    }

    .manual-approval {
      .button {
        font-size: to-rem(12px);
        font-weight: $font-bold;
        margin: to-rem(-33px) 0 0 to-rem(12px);
        padding: to-rem(9px) to-rem(55px);
      }

      .handle-review {
        margin: 0 to-rem(10px) 0 0;
      }

      .complete-review {
        display: block;
        margin: to-rem(40px) auto;
      }

      &__amount {
        margin-bottom: to-rem(35px);
      }

      &__popup {
        .data-card {
          border-radius: to-rem(10px);

          &_title {
            margin-left: auto;
            margin-right: auto;
            max-width: to-rem(352px);
            text-align: center;
          }

          span {
            display: block;
            margin: 0 0 to-rem(15px);
          }
        }
      }
    }
    .data-card_content .load-more-history {
      margin: 10px auto;
    }
  }

  &-main {
    margin-left: 300px;
    padding: 0 to-rem(25px);

    &--center {
      padding: 0 to-rem(32px) to-rem(70px) to-rem(32px);
      max-width: to-rem(950px);
      margin: 0 auto;

      .activate-policy {
        float: right;
      }
    }

    .handle-export {
      margin: to-rem(-42px) to-rem(15px) 0 0;
      padding: to-rem(6px) to-rem(17px);
    }

    .loans {
      .handle-export {
        margin: to-rem(-42px) to-rem(15px) 0 0;
        padding: to-rem(6px) to-rem(17px);
      }

      .export-csv {
        font-size: to-rem(13px);
        font-weight: $font-regular;
        margin-left: to-rem(10px);
      }

      svg {
        vertical-align: text-bottom;
      }
      &-menu {
        color: $originate-deep-blue;
        font-size: to-rem(14px);
        background-color: $color-white;
        padding-left: 20px;
        border-bottom: 1px solid #eaeaea;
        margin-top: 45px;

        a {
          border-top: to-rem(3px) solid transparent;
          box-sizing: border-box;
          font-size: to-rem(14px);
          font-weight: $font-semibold;
          line-height: 21px;
          letter-spacing: to-rem(0.1px);
          margin-right: to-rem(32px);
          margin-bottom: 10px;
          padding-top: 18px;

          &.active {
            border-color: $originate-blue;
          }
        }
      }

      &__manualreview {
        display: inline;
        float: left;
        margin: to-rem(-43px) 0 to-rem(9px) to-rem(260px);

        .button {
          font-size: to-rem(12px);
          line-height: to-rem(12px);
          margin-right: to-rem(14px);
        }
      }
    }

    .customers {
      padding: to-rem(45px) 0 to-rem(25px) 0;

      .button {
        font-weight: $font-regular;
        margin-top: to-rem(-10px);
        padding: to-rem(6px) to-rem(17px);

        .export-csv {
          font-size: to-rem(13px);
          margin-left: to-rem(10px);
        }

        svg {
          vertical-align: text-bottom;
        }
      }

      h5 {
        color: $text-color;
        display: inline-block;
        margin: 0;
      }
    }

    .tooltip {
      display: inline-block;

      &:hover {
        cursor: help;
        content: "";
      }

      &:hover:after {
        background: #6d7381;
        background: rgba(0, 0, 0, 0.8);
        border-radius: to-rem(5px);
        bottom: -23px;
        color: white;
        content: attr(data-title);
        font-size: to-rem(11px);
        left: 0;
        padding: to-rem(5px) to-rem(15px);
        position: absolute;
        z-index: 98;
      }

      &:hover:before {
        border: solid;
        border-color: #333 transparent;
        border-width: 0 6px 6px 6px;
        bottom: 4px;
        content: "";
        left: 1%;
        position: absolute;
        z-index: 99;
      }
    }
  }

  .not-found {
    align-items: center;
    display: flex;
    margin: 0 auto;
    padding: to-rem(150px) to-rem(15px);
    position: relative;
    text-align: center;
    width: 100%;

    .data-card {
      margin: 0 auto;
      padding: to-rem(50px) to-rem(30px);
      max-width: to-rem(600px);
      width: 100%;

      h1 {
        font-size: to-rem(75px);
        margin: 0 0 to-rem(30px);
      }

      .merchant-not-foundbutton {
        background: $color-primary;
        border: 1px solid $originate-blue;
        border-radius: 4px;
        box-shadow: none;
        color: $originate-blue;
        cursor: pointer;
        display: inline-block;
        font-weight: $font-semibold;
        line-height: to-rem(20px);
        padding: to-rem(10px) to-rem(25px);
        outline: none;

        &:hover {
          color: $color-black;
        }
      }
    }
  }
}

.error-layouts {
  background: #f4f5f6;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  gap: 30px;
  padding: 20px;
  justify-content: center;
  width: 100vw;

  ::-moz-selection {
    color: #ffffff;
    background: #0077ff;
    opacity: 1;
    text-shadow: none;
  }

  ::selection {
    color: #ffffff;
    background: #0077ff;
    opacity: 1;
    text-shadow: none;
  }

  .error-wrapper {
    align-items: center;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 600px;
    padding: 48px;
    text-align: center;
    width: 100%;

    .error-message {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      max-width: 432px;
      margin: 0 auto;
    }

    p {
      color: #697275;
      font-size: 15px;
      font-weight: 600;
      max-width: 432px;
      margin: 0 auto 10px;

      a {
        color: #0077ff;
        font-weight: 700;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .use-originate-button {
      background: #fbfbfb;
      border: 1px solid #0077ff;
      border-radius: 4px;
      box-shadow: none;
      color: #0077ff;
      cursor: pointer;
      display: inline-block;
      font-weight: 600;
      line-height: 2rem;
      padding: 1rem 2.5rem;
      transition: 0.3s;
      outline: none;
      overflow-anchor: none;
    }
  }
}