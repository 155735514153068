@import "../../stylesheets/modules/modules";

.checkbox-group {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;

  input {
    position: absolute;
    height: 25px;
    width: 25px;
    left: 0;
    background-color: #eee;
  }

  .checkbox {
    &__label {
      display: flex;
      margin-left: 10px;
      font-weight: 300;
    }
  }
}
.label {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 100px;
}
div.label {
  margin-top: 1px;
  margin-bottom: 5px;
}
.decline-message {
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 18px;
}
.app-number {
  color: red;
}

.text-area {
  margin-bottom: 20px;
}

.loan-history-cw {
  min-width: to-rem(100px);
  max-width: to-rem(200px);
}

.layout-main {
  .portfolios-table {
    span {
      &.tooltip {
        &::before {
          left: to-rem(35px);
        }

        &::after {
          left: to-rem(5px);
        }
      }
    }
    .moreOptions-button {
      margin-top: 10px;
    }
    .opacity-none {
      opacity: 0%;
    }
  }

  .applications-table {
    span {
      &.tooltip {
        &::before {
          left: to-rem(85px);
        }

        &::after {
          left: to-rem(55px);
        }
      }
    }
    .small-txt {
      margin-top: 1.4rem;
      font-size: 1.2rem;
    }
  }
}

.repayment-date {
  font-size: to-rem(16px);
  padding: 0 to-rem(42px);

  &-btn {
    font-weight: $font-bold;
    padding: to-rem(9px) to-rem(55px);
  }

  .react-datepicker-wrapper {
    margin: to-rem(-14px) 0 to-rem(60px) to-rem(60px);
    width: to-rem(278px);
  }
}

.select-repayment-date {
  font-size: to-rem(14px);
  font-weight: $font-semibold;
}

.repayment-date-modal {
  .modal__content {
    padding: to-rem(75px) to-rem(42px);
  }

  .new-radio {
    margin: 0 to-rem(25px) to-rem(10px) 0;
  }
}

.edit-amount {
  padding-bottom: -10px;
  padding-top: -10px;
}
.modify-note {
  font-weight: 500;
  margin-bottom: 80px;
}
.download_button {
  margin-top: to-rem(5px) !important;
  overflow: visible !important;
}
.modify_button {
  .modify {
    float: left;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    width: 40%;
  }
  .cancel {
    float: right;
    width: 40%;
  }
}

.repaymentBreakdown_Foot {
  font-weight: bold;
}

.repayment-breakdown {
  .table__head {
    span {
      padding-right: 5px;
    }
  }

  .table__body {
    span {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .method {
      margin-right: 20px;
    }
    .paid {
      margin-right: 20px;
    }
    .date {
      text-overflow: initial;
    }
  }

  .repayment-tag {
    background: #b8b8b8;
    border-radius: 8px;
    color: #ffffff;
    display: inline-block;
    font-style: normal;
    padding: 4px 0;
    width: 100px;
    text-align: center;

    &__paid {
      background: #55ac68;
    }

    &__pending {
      background: #eeb712;
    }
  }
}

.portfolio-transactions {
  .table__head {
    span {
      display: flex;
      padding-right: 20px;
      justify-content: center;
    }
    
    .action-by {
      margin-right: 10px;
    }
  }

  .table__body {
    span {
      align-items: center;
      justify-content: center;
      padding-left: 30px;
    }
  }

  .opacity-none {
    opacity: 0% !important;
  }

  .moreOptions-button {
    margin-top: -20px;
    padding-top: 10px;
  }

  .transaction-tag {
    background: #b8b8b8;
    border-radius: 8px;
    color: #ffffff;
    display: inline-block;
    font-style: normal;
    padding: 4px 0;
    width: 100px;
    text-align: center;

    &__success {
      background: #55ac68;
    }

    &__pending {
      background: #eeb712;
    }
  }
}

.reverse-transaction {
  p {
    text-align: center;
    font-size: 18px;

    span {
      font-size: 20px;
      color: #000;
      font-weight: 500;
    }
  }
  .buttonGroup {
    display: flex;
    column-gap: 15px;
  }
  .button {
    font-size: to-rem(12px);
    font-weight: $font-bold;
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }

  &__popup {
    .data-card {
      border-radius: to-rem(10px);

      &_title {
        margin-left: auto;
        margin-right: auto;
        max-width: to-rem(352px);
        text-align: center;
      }

      span {
        display: block;
        margin: 0 0 to-rem(15px);
      }
    }
  }
}

.rule-breakdown-popup {
  .modal__holder {
    max-width: 800px;
  }

  .decision-container {
    .rc-collapse {
      background: none;
    }

    .rc-collapse > .rc-collapse-item > .rc-collapse-header {
      color: $originate-blue;
      font-size: to-rem(16px);
      font-weight: $font-semibold;
      line-height: to-rem(30px);
      padding: to-rem(10px) to-rem(15px);
    }

    .rc-collapse-content-box {
      .rule-decision {
        margin: 0 0 to-rem(30px);

        h6 {
          margin: 0 0 to-rem(10px);
        }

        &__result {
          display: flex;
          margin-right: to-rem(10px);

          &-text {
            line-height: 20px;
          }
        }

        &__item {
          align-items: center;
          display: flex;
          list-style: none;
          margin: 0 0 to-rem(15px);

          &-holder {
            padding: 0 0 0 to-rem(10px);
          }
        }
      }
    }
  }
}

.wallet-title {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.wallet-popup-button {
  @extend .wallet-title;
  justify-content: space-evenly;
}

.supporting-doc {
  padding: 40px;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;

    @include sm-phone {
      align-items: center;
      flex-direction: column;
      max-width: $mobile-width;
    }
  }

  &__card {
    align-items: center;
    background: rgba(0, 119, 255, 0.05);
    border-radius: to-rem(5px);
    border: 1px dashed #4c5c6d;
    box-shadow: 0 0 to-rem(4.24219px) rgba(232, 232, 232, 0.533854);
    display: flex;
    flex-direction: column;
    font-size: to-rem(14px);
    font-style: normal;
    width: 100%;
    height: auto;
    margin: 0 30px 30px 0;
    max-width: to-rem(284px);
    min-height: to-rem(190px);
    min-width: to-rem(250px);
    padding: to-rem(15px) to-rem(18px);
    position: relative;

    button {
      &.viewFile {
        background: $color-white;
        border-radius: to-rem(10px);
        border: 1px solid $color-white;
        padding: to-rem(2px) to-rem(10px);
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 to-rem(4.24219px) rgba(0, 119, 255, 0.5);
        }
      }
    }

    &.flexCenter {
      justify-content: center;
      text-align: center;
    }

    &.active {
      border: 1px solid $originate-blue;
      box-shadow: 0 0 to-rem(4.24219px) rgba(0, 119, 255, 1);
    }

    &.custom {
      .title {
        color: $originate-blue;
      }
    }

    &:hover {
      box-shadow: 0 0 to-rem(4.24219px) rgba(164, 164, 164, 0.533854);

      .more-options {
        display: inline-block;
        margin-right: 15px;
        position: absolute;
        right: 0;

        svg {
          display: block;
          margin-left: to-rem(13px);
          margin-top: to-rem(-4px);
        }

        &:hover {
          .more-content {
            background: $color-white;
            margin: to-rem(40px) 0 0 0;
            width: to-rem(138px);
            * {
              padding: 0.6rem 1rem;
            }
          }
        }
      }
    }

    .more-options {
      display: none;
    }

    .icon {
      margin: to-rem(25px) 0 to-rem(-30px) 0;
    }

    .new-doc {
      color: $text-color;
      font-size: 14px;
      font-weight: $font-regular;
      margin-top: to-rem(9px);
    }

    .title {
      color: $text-color;
      font-weight: $font-semibold;
      font-size: 16px;
      margin: to-rem(45px) 0 to-rem(6px) 0;
      text-align: center;
      word-break: break-word;
      width: 100%;
    }

    .desc {
      text-align: center;

      a {
        background: rgba($originate-blue, 0.15);
        border-radius: 20px;
        color: $originate-blue;
        display: inline-block;
        font-weight: $font-regular;
        font-size: to-rem(14px);
        line-height: 15px;
        margin: 0 auto;
        padding: 5px 15px;
        text-align: center;
      }

      width: 100%;
    }
  }

  .icon-style {
    height: 30px;
    width: 30px;
    margin: 0 auto;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    &.top {
      margin-top: 45px;
    }

    &.enlarge {
      height: 50px;
      width: 50px;
    }

    &.enlarge-success {
      height: 70px;
      width: 70px;
      margin-top: 25px;
    }
  }

  .upload-text {
    align-items: center;
    margin: 8px 0 50px 0;
    font-size: 16px;
  }

  .upload-button {
    margin-top: 15px;
    text-align: center;
  }

  .success-text-wrap {
    margin-top: 35px;
    text-align: center;
    font-size: 14px;

    &.success-text {
      margin-top: 5px;
      align-items: left;
    }

    ul {
      text-align: center;
      margin: 0;
      padding: 0;
      width: 100%;
      li {
        list-style: none;
        text-align: left;
        justify-content: left;
        font-size: 16px;
        margin: 0 auto;
        width: 80%;
        padding: 10px 0 45px 15px;
      }
    }
  }

  .upload-document-wrapper {
    border-radius: 5px;
    border: 1px dashed #d9c9ce;
    background-color: #d9c9ce00;
    text-align: center;
    margin-top: 20px;
    box-sizing: border-box;
    position: relative;
    height: 100%;

    img {
      position: absolute;
      z-index: 50;
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      border: none;
      font-size: 15px;
      font-weight: bold;
      text-transform: uppercase;
      background-color: #fff;
      padding-top: 15px;
    }

    input {
      position: absolute;
      z-index: 100;
      justify-content: center;
      flex-flow: column nowrap;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      outline: none;
      opacity: 0;
      cursor: pointer;
    }
  }
}

.request-supp-doc-modal {
  .modal__content {
    padding: 50px;

    .document-card {
      display: flex;
      justify-content: space-between;
      position: relative;

      .input_group {
        width: calc(100% - 25px);
      }

      .close-btn {
        color: $red-c12;
        cursor: pointer;
        display: none;
        font-size: to-rem(18px);
        margin-top: to-rem(37px);
        right: -3px;
        position: absolute;
      }

      &:hover,
      &:focus {
        .close-btn {
          display: block;
        }
      }
    }

    .add-new-doc {
      cursor: pointer;
      margin-bottom: 20px;

      svg {
        vertical-align: middle;
      }

      span {
        margin-left: to-rem(20px);
      }
    }
  }
}

.new-document-modal {
  .modal__content {
    padding: to-rem(32px) to-rem(60px);

    .new-doc-subtext {
      font-size: to-rem(15px);
      margin-top: to-rem(5px);
      text-align: center;
    }

    .send-req {
      padding: to-rem(10px) to-rem(35px);
    }
  }
}

.signed-offer-letter {
  .action-buttons {
    display: flex;
    justify-content: space-between;
    .button {
      padding: to-rem(10px) to-rem(60px);
    }
  }
  .subtext {
    margin-top: to-rem(-15px);
    text-align: center;
  }
  .signed-letter {
    align-items: center;
    background: $grey-f1f;
    display: flex;
    flex-direction: column;
    height: to-rem(170px);
    justify-content: center;
    margin: to-rem(50px) auto;
    max-width: 100%;
    padding: to-rem(20px);
    text-align: center;
    width: to-rem(262px);
    svg {
      height: 33px;
      stroke: $grey-6ef;
      width: 28px;
    }
    &-filename {
      color: $grey-6ef;
      display: block;
    }
  }
}

.right-text {
  .moratorium-period {
    margin-bottom: -20px;
    .button {
      background: none;
      border: none;
      padding: 0;
      text-decoration: none;
    }
  }
}

.moratorium-popup {
  .popup {
    .data-card {
      .moratorium-text-container {
        .moratorium-text {
          margin-bottom: 20px;
          text-align: center;
        }
        .current-repayment {
          span {
            font-weight: 600;
          }
        }
      }

      .duration-aside {
        border-left: 1px solid rgba(6, 25, 47, 0.21);
        text-align: center;
        padding: 12px 0;
        width: 100px;
        float: right;
        margin-top: -69px;
      }

      .moratoriumcheckbox-container {
        position: relative;

        input {
          position: absolute;
          height: 20px;
          width: 20px;
          top: 25%;
          left: 0;
          background-color: #eee;
          cursor: pointer;
        }

        .checkbox {
          &__label {
            display: flex;
            margin-left: 22px;
            font-weight: 400;
            cursor: pointer;
          }
        }
      }

      .moratorium-button-container {
        margin-top: 48px;
        display: flex;
        justify-content: space-between;

        .button {
          width: 150px;
        }
      }
    }
  }
}

.processing-fee-button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 65px;
  padding: 25px 0 10px 0;

  button {
    width: 150px;
  }
}

.processingfee-edit-container {
  display: flex;
  .edit-button-container {
    margin: -9px 0 -26px 12px;
    .button {
      background: none;
      border: none;
      padding: 0;
      text-decoration: none;
    }
  }
}

.perform-action {
  margin-left: auto;
  border: 1px solid $originate-blue;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  width: 155px;
  background: $color-white;
  position: relative;
  margin-top: -40px;
  height: 40px;

  &__name {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: $originate-blue;
    width: 155px;
    margin-top: 7px;
    font-size: to-rem(14px);
    font-weight: 600;
  }

  &__dropdown {
    margin-top: to-rem(3px);

    > * {
      width: 100%;
      border: 1px solid rgba(201, 206, 217, 0.5);
      box-sizing: border-box;
      color: $text-color;

      &:hover {
        cursor: pointer;
        background: $blue-f1f;
      }
    }
  }

  .dd-wrapper {
    color: $color-black;
  }

  .dropdown {
    background: transparent;
    box-shadow: none;
    margin: 0 0 0 to-rem(-25px);

    .button {
      font-weight: 500;
      font-size: 14px;
      padding: to-rem(10px) to-rem(17px);
    }
  }
}

.close-out-loan_text {
  text-align: center;
  font-size: to-rem(15px);
}

.flex-display {
  display: flex;

  .button.custom {
    background: none;
    font-weight: 500;
    margin: auto;
    padding: 0;
    transition: 0.3s;

    &:hover {
      text-decoration: underline;
    }
  }
}

.textAreaHeight {
  height: 200px;
}

.repayment-top-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .filter-repayments {
    align-items: center;
    display: flex;
    gap: 10px;
    padding-left: 15px;

    label {
      font-weight: $font-semibold;
    }

    &-select {
      width: 120px;

    select {
      padding: 7.5px 10px;
      line-height: 1;
    }
    }
  }
}

.edit-repayment {
  &-form {
    .react-datepicker-wrapper {
      display: block;
    }
  }

  &-buttons {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  &-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr max-content;
    gap: 20px;

    &__title {
      font-weight: $font-bold;
      text-align: center;
    }

    .delete-repayment-button {
      align-items: center;
      display: flex;
      width: 75px;

      button {
        width: 100%;
      }

      .repayment-tag {
        display: block;
        font-size: 11px;
        height: 30px;
        opacity: 0.5;
        width: 100%;
      }
    }
  }

  &-input {
    background: none;
    border-radius: 0;
    padding: 5px;
    font-size: 14px;
    line-height: 24px;
    display: inline-block;
    text-align: center;
    width: 100%;
    max-width: none;

    &:disabled {
      opacity: 0.5;
    }
  }


    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.7rem;

}

.save-repayment-modal {
  .modal__holder {
    max-width: to-rem(768px);
  }

  .modal__content-title {
    margin: 0;
  }

  .save-repayment-preview {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 32px 0 0;
    padding: 0;

    &-row {
      border-bottom: 1px solid #dedede;
      display: grid;
      gap: 15px;
      grid-template-columns: repeat(4, 1fr);
      padding-bottom: 15px;
    }

    &__header {
      font-weight: $font-bold;
    }

    li {
      list-style: none;
    }
  }
}


.delete-file {
  align-items: center;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 40px;
}

.v2-modal {
  position: relative;

  .close-icon {
    height: 15px;
    width: 15px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 22px 30px;
  
    &:hover {
      cursor: pointer;
    }
  }

  .customer-data {
    background: #D6DDEB80;
    padding: 10px 15px;

    .customer-data__text {
      color: #2C2C2C !important
    }
  }
  
  .clear-customer-close-icon {
    height: 15px;
    width: 15px;
  }
  
  .v2-modal-button-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
  }
  
  .flex-grow {
    flex-grow: 1;
  }
  
  .cashrepayment-left-text {
    text-align: left !important;
    text-transform: capitalize !important;
  }
  
  .cashrepayment-right-text {
    text-align: right !important;
  }
  
  .manual-approval__amount__section{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  .payment-input {
    margin-bottom: 20px
  }
  .list-style-none {
    list-style: none;
  }
}

.loans-section {
  
  .loan-header {
    margin-left: -20px;
  }
  
  .due-date {
    margin-right: 30px;
  }
  
}

.table-buttons-wrapper {
  display: flex;
  justify-content: end;
  .cash-repayment-button {
    height: 35px;
  }
  .export-button {
    height: 35px;
  }
}