@import "../../stylesheets/modules/modules";

.tableau-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 30px;
    text-align: center;
    line-height: 35px;

    span {
      font-size: 20px;
      color: #4e4d4d;
    }
  }
}

.metabase-wrapper {
  align-items: stretch;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 600px;
  height: calc(100vh - 220px);
  padding: 20px 30px;

  iframe {
    height: 100%;
    background: white;
  }
}

.reports-wrapper {
  display: flex;
  flex-direction: column;
}

.reports-navbar {
  background: linear-gradient(
    0deg,
    #fbfbfb 100%,
    rgba(201, 206, 217, 0.95) 47.65%,
    rgba(251, 251, 251, 0) -376.25%
  );
  display: flex;
  flex-direction: row;
  margin-top: -20px;
  padding: 1.5rem 3.2rem 0;
  box-shadow: to-rem(1px) to-rem(3px) to-rem(8px) rgba(0, 0, 0, 0.02);

  
  a {
    font-size: 14px;
    font-weight: $font-semibold;
    border-bottom: 3px solid transparent;
    padding: 0 15px 15px;

    &.active {
      border-bottom-color: $originate-blue;
    }
  }
}
