@import '../../stylesheets/modules/modules';

.more-options {
  background: rgba(0, 0, 0, 0.025);
  width: 30px;
  height: 30px;
  transition: 0.3s;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 2px 10px 1px !important;

  &:hover {
    background: rgba(0, 0, 0, 0.08);

    .more-content {
      border-top: none;
      top: 0;
      position: absolute;
      display: block;
      margin: 0px 0px 0px -30px;
      background: rgba(255, 255, 255, 0.7);
      box-shadow: 3px 4px 24px rgba(0, 0, 0, 0.15);
      border: 1px solid rgba(201, 206, 217, 0.95);
      padding: to-rem(0px) to-rem(0px);

      * {
        display: block;
        padding: to-rem(10px) to-rem(10px);

        &:hover {
          background: $grey-f1f;
          color: $originate-blue;
          cursor: pointer;
        }
      }

      > a {
        font-size: to-rem(14px);
        line-height: to-rem(24px);
      }

      &:hover {
        display: block;
        background: rgba(255, 255, 255, 1);
      }
    }
  }

  .more-content {
    display: none;
    top: -3px;
    z-index: 2;
    border-radius: 2px;
    transition: height 900ms;
  }
}
