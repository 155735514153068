@import '../../stylesheets/modules/modules';

.more-options {
  background: rgba(0, 0, 0, 0.025);
  width: 30px;
  height: 30px;
  transition: 0.3s;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 2px 10px 1px !important;

  &:hover {
    background: rgba(0, 0, 0, 0.08);

    .more-content {
      border-top: none;
      top: to-rem(-13px);
      position: absolute;
      display: block;
      margin: 0px 0px 0px -30px;
      background: rgba(255, 255, 255, 0.7);
      box-shadow: 3px 4px 24px rgba(0, 0, 0, 0.15);
      border: 1px solid rgba(201, 206, 217, 0.95);
      padding: to-rem(0px) to-rem(0px);

      * {
        display: flex;
        color: $originate-deep-blue;
        padding: to-rem(10px) to-rem(10px);

        &:hover {
          color: $originate-blue;
        }
      }
    }

    svg {
      height: to-rem(40px);
      margin: to-rem(-12px) to-rem(-4px) to-rem(-6px) to-rem(-7px);
      width: to-rem(40px);
    }
  }

  .more-content {
    display: none;
    top: -3px;
    z-index: 10;
    border-radius: 2px;
    transition: height 900ms;

    button {
      border: none;
      width: 100%;
      background: $color-white;
    }
  }
}

.actions-user-popup {
  .data-card {
    &_content {
      .actions {
        margin-top: to-rem(15px);
      }
    }
  }
}

.user-creation {
  .data-card {
    .form-fields {
      .group-title {
        text-transform: capitalize;
        font-weight: 600;
        font-size: to-rem(14px);
        color: $dark-blue;
        margin-bottom: to-rem(7px);
      }

      .card-content {
        max-width: 600px;
        input[type='text'],
        input[type='number'] {
          width: 100%;
        }

        div {
          &.checkbox-group {
            font-size: to-rem(15px);

            .default {
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=30)';
              filter: alpha(opacity=30);
              opacity: 0.3;
            }

            .checkbox__label {
              font-weight: 400;
            }
          }
        }

        .addition-privileges {
          margin: to-rem(15px) to-rem(0) to-rem(5px) to-rem(-33px);
        }
      }
    }
  }
}

.caption {
  font-weight: $font-bold;
  padding: 0 6px;
}

.user_management {
  padding: 0;

  .action_button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .button {
    height: 40px;
  }

  .delete_button {
    background: #fbfbfb;
    color: #c1292e;
    border: 1px solid #c1292e;
  }

  .table {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .table_line {
    height: 55px;
    width: 100%;
  }
}

.role-listing {
  display: grid;
  gap: to-rem(30px);
  grid-template-columns: 2.5fr 7.5fr;
  max-width: to-rem(1200px);
  padding: 0 20px;
  width: 100%;

  .role-item-col {
    position: sticky;
    top: 300px;

    .roles-holder {
      background: $color-white;
      border-radius: 3px;
      box-shadow: to-rem(1px) to-rem(3px) to-rem(8px) rgba(0, 0, 0, 0.02);
      padding: to-rem(20px);
      position: sticky;
      top: 170px;
    }

    .items {
      background: $color-white;
      cursor: pointer;
      font-size: 13px;
      font-weight: $font-semibold;
      line-height: 20px;
      margin: 0 0 2px;
      padding: to-rem(10px);
      text-transform: capitalize;
      transition: 0.3s;

      &:hover,
      &.is_selected {
        background: rgba(0, 119, 255, 0.05);
      }

      &.is_selected {
        background: rgba(0, 119, 255, 0.05);
        color: $originate-blue;
      }
    }
  }

  .role-info {
    flex: auto;
  }

  .space-right {
    margin-right: to-rem(15px);
  }

  .readonly-chk {
    cursor: not-allowed;
  }
}

.change-role-popup {
  .form-fields {
    .card {
      display: block;
    }
  }
}

.email-prompt {
  font-size: 1.8rem;
  text-align: center;
  .email {
    color: $originate-blue;
    margin-left: 4px;
  }
}

.edit-user {
  .form-desc {
    padding-left: 32px;
  }

  .form-fields {
    .flex-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      .form-main {
        padding-top: 30px;

        .card {
          width: 100%;

          .input {
            width: 544px;
          }
        }
      }

      .form-sub {
        display: flex;
        padding-bottom: 60px;

        .card {
          margin-right: 44px;
          width: 100%;

          .input {
            width: 250px;
          }
        }
      }
    }
  }
}

.approval-workflow {
  &.form-card {
    max-width: to-rem(1050px);
    margin: 0 auto;
  }

  &__wrapper {
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;

    a {
      &:hover {
        display: inherit;
      }
    }

    &.wrap {
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    @include sm-phone {
      flex-direction: column;
      align-items: center;
      max-width: $mobile-width;
    }
  }

  &__card {
    width: 100%;
    height: auto;
    min-height: to-rem(190px);
    min-width: to-rem(250px);
    max-width: to-rem(300px);
    font-style: normal;
    font-size: to-rem(14px);
    background: $color-white;
    box-shadow: 0 0 to-rem(4.24219px) rgba(232, 232, 232, 0.533854);
    border-radius: to-rem(6px);
    margin-bottom: 30px;
    margin-right: to-rem(41px);
    padding: to-rem(16px) to-rem(18px);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &.flexStart {
      justify-content: flex-start;
    }

    &.flexCenter {
      justify-content: center;
      text-align: center;
    }

    &.active {
      border: 1px solid $originate-blue;
      box-shadow: 0 0 to-rem(4.24219px) rgba(0, 119, 255, 1);
    }

    &.custom {
      .title {
        color: $originate-blue;
      }
    }

    &:hover {
      box-shadow: 0 0 to-rem(4.24219px) rgba(164, 164, 164, 0.533854);

      .more-options {
        display: inline-block;
        position: absolute;
        right: 0;
        margin-right: 15px;

        svg {
          display: block;
          margin-left: to-rem(13px);
          margin-top: to-rem(-4px);
        }

        &:hover {
          .more-content {
            width: to-rem(90px);
            margin: to-rem(10px) 0 0 to-rem(-78px);

            * {
              padding: 0.6rem 1rem;
            }
          }
        }
      }
    }

    .more-options {
      display: none;
    }

    .title {
      font-weight: 600;
      font-size: 18px;
      text-transform: capitalize;
      color: $text-color;
      margin: to-rem(14px) 0 to-rem(9px) 0;
      width: 100%;
    }

    .desc {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $text-color;
      opacity: 0.9;
      width: 100%;
    }
  }
}

.create-approval-workflow {
  .data-card {
    .form-desc {
      margin-right: to-rem(40px);
    }

    .amount-section {
      margin-bottom: 12px;
    }

    .form-fields {
      position: relative;

      &.sections {
        display: block;
      }

      &.step-text {
        .card {
          min-width: to-rem(45px);
        }
      }

      .remove-step {
        position: absolute;
        right: 0;
        z-index: 10;
        margin-top: to-rem(18px);

        &:hover {
          cursor: pointer;
        }

        svg {
          height: to-rem(55px);
        }
      }

      .add-new-step {
        color: $originate-blue;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.1px;
        max-width: to-rem(130px);
        margin: 0 auto;

        &:hover {
          cursor: pointer;
          color: $originate-blue;
        }

        svg {
          height: to-rem(19px);
          margin-right: to-rem(5px);
        }
      }

      .card {
        margin-bottom: to-rem(20px);
        margin-right: to-rem(30px);

        label.input_group {
          max-width: to-rem(212px);
        }
      }
    }
  }

  .actions {
    margin-right: to-rem(10px);
  }
}

.input {
  &[disabled] {
    background: rgba(242, 242, 242, 0.8) no-repeat;
    cursor: not-allowed;
  }
}

.checkbox__disabled {
  opacity: 0.8;
}

.webhooks {
  .data-card {
    padding: to-rem(40px) to-rem(40px) to-rem(70px);

    .form-desc {
      margin-right: to-rem(25px);
      max-width: to-rem(300px);
    }

    .form-fields {
      .input {
        width: to-rem(535px);
      }
    }
  }
}

.config {
  .form-fields {
    .flex-container {
      &.spacing {
        margin-bottom: 20px;
      }
    }

    .grid-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
    }
  }
}

.upload {
  .form-desc {
    max-width: 130px !important;
  }
  .form-fields {
    .grid-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 35px;

      &.spacing {
        margin-bottom: 30px;
      }
      .view {
        display: flex;
        flex-direction: column;

        width: fit-content;
      }
      .url-link {
        color: $originate-blue;
        margin: 10px 0;
      }
    }
  }
  .divider {
    border-top: 1px solid grey;
    margin: 40px 0;
  }
  .button {
    &.link {
      color: #737373;
      font-weight: 700;
      font-size: 14px;
      margin: 10px 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.notification-preferences {
  .form-fields {
    .flex-container {
      align-items: center;
      &.spacing {
        margin-bottom: 5px;
      }
    }
    .grid-container {
      display: grid;
      grid-template-columns: 10rem 10rem;
    }
  }
  .divider {
    border-top: 1px solid grey;
    margin: 0 0 10px 0;
  }
  button {
    margin-top: 2rem;
  }
}


@include phone {
  .approval-workflow {
    margin: 0 to-rem(15px) 0 to-rem(15px);

    &__wrapper {
      flex-direction: column;
      align-items: center;
      max-width: $mobile-width;
    }

    &__card {
      margin: 0 0 to-rem(15px) 0;
    }
  }
}
