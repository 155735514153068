@import "../../stylesheets/modules/modules";

.table__actions {
	display: flex;
	justify-content: flex-end;
	border-bottom: 1px solid $grey-eae;
	margin-top: to-rem(-15px);
	margin-bottom: to-rem(10px);
	padding-bottom: to-rem(16px);

	button {
		padding: to-rem(5px) to-rem(20px);

		svg {
			margin-right: to-rem(10px);
		}
	}
}

.customerdetails-sidebar {
	.policy-details {
		padding-bottom: to-rem(2px);
	}
}

.flex-container {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	align-items: baseline;
}

.space-button {
	margin-top: 15px;
}

.space-between {
	justify-content: space-between;
}

.pad {
	padding-top: 30px;
}

.align-center {
	align-items: center;
}

.justify-end {
	justify-content: flex-end;

	> a {
		margin-right: to-rem(15px);
	}
}

.center {
	display: flex;
	justify-content: center;
}

.required {
	font-weight: 600;
	color: $color-status-rejected;
}

.supp-doc-required {
	align-items: center;
	color: $color-status-rejected;
	display: flex;
	font-weight: 600;
	justify-content: center;
}

.form-card {
	width: 100%;
	max-width: to-rem(950px);
	margin: 0 auto;
	margin-bottom: to-rem(40px);

	.data-card {
		border-top: 4px solid $originate-blue;
		border-radius: 3px;
		margin: 0 to-rem(5px);
		padding: to-rem(20px);
		margin-bottom: 30px;

		.card {
			display: block;
		}

		.double-col {
			> div {
				&:first-child {
					margin-right: 35px;
				}

				.card {
					margin-bottom: to-rem(20px);
				}
			}
		}

		.form-desc {
			width: 100%;
			max-width: 250px;
			margin-right: 10px;
		}

		.repayment-breakdown {
			border: none;
			padding: 0;
			font-weight: 700;
			text-decoration: underline;
			margin-top: 20px;
		}

		.form-fields {
			flex-grow: 2;

			.form-sub {
				display: flex;

				.card {
					margin-right: 44px;
					width: 100%;

					.input {
						width: 250px;
					}

					.select {
						width: 250px;
					}
				}
			}

			.interest-calc-method:hover #interest-calc-method_dropdown {
				display: block;
			}

			#interest-calc-method_dropdown {
				background: $color-white;
				border: 1px solid $grey-f1f;
				box-shadow: 0px 10px 10px rgba(58, 132, 255, 0.07);
				border-radius: 3px;
				display: none;
				font-size: to-rem(12px);
				line-height: to-rem(17px);
				max-width: to-rem(380px);
				padding: to-rem(10px) to-rem(20px);
				position: absolute;
				right: 200px;
				top: 345px;
				width: 100%;

				span {
					font-weight: $font-semibold;
				}
			}

			&.sections {
				background: $color-white;
				border: 1px solid $color-off-white;
				box-sizing: border-box;
				box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.02);
				border-radius: 4px;
				padding: to-rem(10px) to-rem(15px);

				.input_label {
					color: $grey-8a8;

					&:hover {
						cursor: default;
					}
				}

				.input,
				select {
					padding: to-rem(5px) to-rem(15px);
					width: 100%;
				}
			}
			.input,
			textarea {
				background: $grey-fcf;
				border: 1px solid $grey-efe;
				box-sizing: border-box;
				border-radius: 2px;
			}

			select {
				background-color: $grey-fcf;
				border: 1px solid #efefef;
			}

			.input,
			select {
				padding: to-rem(5px) to-rem(15px);
				width: 100%;
			}

			.input,
			textarea {
				background: $grey-fcf;
				border: 1px solid $grey-efe;
				box-sizing: border-box;
				border-radius: 2px;
			}

			select {
				background-color: $grey-fcf;
				border: 1px solid #efefef;
			}

			.card-title {
				font-size: to-rem(14px);
				color: $originate-deep-blue;
			}
		}
	}

	.modal__holder {
		max-width: to-rem(961px);

		.form-fields {
			align-items: center;
			margin-left: 0 !important;
		}

		.table {
			padding: to-rem(35px);

			&__head {
				span {
					background: rgba(201, 206, 217, 0.1);
					text-align: left;
				}
			}

			&__body {
				li:nth-child(even) {
					background: rgba(201, 206, 217, 0.1);
				}

				span {
					text-align: left;
				}
			}

			&__footer {
				span {
					text-align: left;
				}
			}
		}
	}
}

@include phone {
	.form-card {
		.data-card {
			margin: 0 to-rem(15px);
			margin-bottom: to-rem(30px);

			.form-desc {
				max-width: inherit;
				margin-bottom: to-rem(15px);
			}

			.form-fields {
				width: 100%;

				.radio-input {
					display: flex;

					input {
						width: 100%;
					}
				}
			}

			.flex-container {
				display: block;

				&.double-col {
					.sections {
						margin-bottom: to-rem(30px);
					}
				}
			}
		}

		.justify-end {
			justify-content: center;
		}
	}
}

.loan-product {
	.card-content {
		p {
			color: #bf1650;
		}

		p::before {
			display: inline;
			content: "⚠ ";
		}
	}

	.form-card {
		width: 100%;
		max-width: to-rem(1117px);
		margin: 0 auto;
		margin-bottom: to-rem(40px);

		.data-card {
			border-top: 4px solid $originate-blue;
			border-radius: 3px;
			margin: 0 to-rem(5px);
			padding: to-rem(20px);
			margin-bottom: 30px;

			.card {
				display: block;
			}

			.form-desc {
				margin: 12px;
				margin-left: 25px;
				width: 100%;
			}
			.form-fields {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				width: 100%;
				margin-left: 71px;

				&.sections {
					background: $color-white;
					border: 1px solid $color-off-white;
					box-sizing: border-box;
					box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.02);
					border-radius: 4px;
					padding: to-rem(10px) to-rem(15px);

					.input_label {
						color: $grey-8a8;

						&:hover {
							cursor: default;
						}
					}

					.input,
					select {
						padding: to-rem(5px) to-rem(15px);
						width: 100%;
					}
				}
				.input,
				select {
					padding: to-rem(5px) to-rem(15px);
					width: 100%;
				}
				.card-title {
					font-size: to-rem(14px);
					color: $originate-deep-blue;
					margin: 0 0 to-rem(7px);
				}
			}
		}
	}

	.product-details {
		.form-card {
			.data-card {
				.form-fields {
					.input-container {
						.input {
							width: 477px;
							height: 36px;
							margin-right: 20px;
						}

						select {
							width: 181px;
							height: 36px;
						}
					}

					#processing-fee-type {
						display: none;
					}

					#processing-fee-type-edit{
						display: block;
					}

					#early-repayment, #requires-debit-mandate {
						padding: 4px;
					}

					.radio-button {
						margin-left: -12px;
						.radio-input {
							display: flex;
							margin-top: -10px;

							input {
								width: 255px;
								margin-left: -24px;
							}

							.filter-range {
								background: $color-white url("../../static/icons/arrow-right.svg") center to-rem(15px)
									no-repeat;
								border: 1px solid #dddddd;
								display: flex;
								flex-direction: row;
								justify-content: space-between;
								margin-bottom: to-rem(25px);
								padding: to-rem(6px) to-rem(10px);
								width: 100%;
								margin-left: -24px;

								.filter-input,
								input {
									margin-top: 0;
									height: to-rem(25px);

									&:first-of-type {
										margin-left: to-rem(20px);
										max-width: to-rem(75px);
										width: 100%;
									}

									&:last-of-type {
										margin-right: to-rem(20px);
										max-width: to-rem(75px);
										width: 100%;
									}
								}

								.react-datepicker-wrapper {
									width: 100px;

									&:first-of-type {
										input {
											margin-left: to-rem(14px);
										}
									}

									&:last-of-type {
										input {
											margin-left: to-rem(14px);
										}
									}
								}

								input {
									background: none;
									border-radius: 0;
									border: none;
									padding: 0;
								}
							}
						}

						.radio_inputbox {
							margin: to-rem(-17px) 0 0 to-rem(35px);
						}
					}

					select {
						background-color: $grey-fcf;
						border: 1px solid #efefef;
						width: 488px;
						height: 36px;
					}

					.require-offer-letter {
						display: flex;
						background-color: #fcfcfc;
						border: 1px solid #efefef;
						justify-content: space-between;
						width: 488px;
						height: 36px;
						padding: 0.5rem 1.5rem;
						margin: 0 0 2rem;
					}
				}
			}
		}
		@include phone {
			.form-card {
				.data-card {
					margin: 0 to-rem(15px);
					margin-bottom: to-rem(30px);

					.form-desc {
						max-width: inherit;
						margin-bottom: to-rem(15px);
					}

					.form-fields {
						width: 100%;

						.input-container {
							width: 100%;

							.input {
								width: 200px;
							}
						}

						.form-sub {
							display: block;

							.input,
							select {
								width: 200px;
							}
						}

						.radio-button {
							.radio-input {
								.filter-range {
									width: 200px;

									.filter-input,
									input {
										&:first-of-type {
											width: 60px;
										}

										&:last-of-type {
											width: 80px;
										}
									}
								}
							}
						}

						.input,
						select {
							width: 200px;
						}

						#interest-calc-method_dropdown {
							right: 0;
							top: 500px;
						}
					}

					.flex-container {
						display: block;

						&.double-col {
							.sections {
								margin-bottom: to-rem(30px);
							}
						}
					}
				}

				.justify-end {
					justify-content: center;
				}

				.input_warning {
					width: 100%;
				}
			}
		}
	}

	.page {
		.double-buttons {
			display: flex;
			justify-content: space-between;
			margin: 0 365px 52px;

			.button.secondary {
				width: to-rem(194px);
			}

			.button {
				width: to-rem(194px);
			}
		}

		.single-button {
			display: flex;
			justify-content: center;
			margin-bottom: to-rem(52px);

			.button.secondary {
				width: to-rem(194px);
			}

			.button {
				width: to-rem(194px);
			}
		}

		@include phone {
			.double-buttons {
				.button.secondary {
					width: auto;
					padding: 0 40px;
				}

				.button {
					width: auto;
					margin-left: -300px;
					margin-right: 40px;
				}
			}

			.single-button {
				.button.secondary {
					width: auto;
				}

				.button {
					width: auto;
				}
			}
		}
	}

	.loan-details {
		.form-card {
			.data-card {
				.form-fields {
					.range {
						display: flex;
						justify-content: space-between;
						margin-left: to-rem(-140px);
						width: 78%;

						&:first-of-type {
							background: $color-white url("../../static/icons/arrow-right.svg") 300px 42px no-repeat;
						}
					}

					.graduated_loans {
						display: none;
					}

					select {
						background-color: $grey-fcf;
						border: 1px solid #efefef;
						width: to-rem(488px);
						height: to-rem(36px);
					}

					.cycle {
						display: flex;
						flex-direction: column;

						> .form-fields {
							position: relative;
						}

						.step-text {
							margin-left: to-rem(-66px);
						}

						.cycle-steps {

							> .remove-step {
								position: absolute;
								right: to-rem(22px);
								z-index: 0;
								margin-top: 65px;
							}

							.cycle-input {
								display: flex;
								width: 100%;
								margin-left: to-rem(-70px);

								.input {
									width: to-rem(252px);
								}

								&:first-of-type {
									margin-right: to-rem(-10px);
								}
							}
						}

						.add-new-step {
							color: $originate-blue;
							align-items: center;
							font-weight: 600;
							font-size: 14px;
							letter-spacing: 0.1px;

							&:hover {
								cursor: pointer;
								color: $originate-blue;
							}

							svg {
								height: to-rem(19px);
								margin-right: to-rem(5px);
							}
						}

						.loan-tenure {
							margin: to-rem(-20px) 0 to-rem(40px) 10px;

							.input {
								width: to-rem(220px);
							}
						}
					}

					.remove-step {
						position: absolute;
						right: to-rem(320px);
						z-index: 0;

						&:hover {
							cursor: pointer;
						}
					}
				}
			}
		}

		@include phone {
			.form-card {
				.data-card {
					margin: 0 to-rem(15px);
					margin-bottom: to-rem(30px);

					.form-desc {
						max-width: inherit;
						margin-bottom: to-rem(15px);
					}

					.form-fields {
						width: 100%;

						.range {
							display: flex;
							flex-direction: column;
							width: 50%;
						}

						select {
							width: auto;
						}

						.cycle {
							display: flex;
							flex-direction: column;

							.cycle-steps {
								.cycle-input {
									display: flex;
									flex-direction: column;
									width: 100%;
									margin-top: to-rem(15px);

									.input {
										width: auto;
									}
								}
							}
						}
					}
				}
			}
		}
		@include tablet {
			.form-card {
				.data-card {
					margin: 0 to-rem(15px);
					margin-bottom: to-rem(30px);

					.form-desc {
						max-width: inherit;
						margin-bottom: to-rem(15px);
					}

					.form-fields {
						width: 100%;

						.cycle {
							display: flex;

							.cycle-steps {
								.cycle-input {
									display: flex;
									width: 100%;

									.input {
										width: auto;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
