@import "../../stylesheets/modules/modules";

.bread-crumbs{
  display: inline;
  font-size: to-rem(16px);

  .bread-crumb{
    display: inline-block;
    margin-right: to-rem(10px);
    opacity: .75;

    .transform {
      text-transform: capitalize;
    }

    .hiddenNavStep {
      display: none;

      &:after {
        content: "";
      }
    }

    &:last-of-type{
      opacity: 1;
    }

    &.false {
      &:after {
        content: "";
        margin: 0;
      }
    }

    &:after{
      content: ">";
      margin-left: to-rem(10px);
    }

    &:last-of-type{
      margin: 0;

      &:after{
        content: none;
      }
    }

    a{

      &:hover{
        text-decoration: underline;
      }
    }
  }
}
