@import "../../stylesheets/modules/modules";

.alert{
  background: $light-blue;
  border: to-rem(1px) solid $originate-blue;
  border-radius: to-rem(55px);
  box-shadow: to-rem(0px) to-rem(0px) to-rem(4.24219px) rgba(232, 232, 232, 0.533854);
  height: to-rem(45px);
  left: to-rem(410px);
  top: to-rem(175px);
  text-align: center;
  width: 100%;

  &.error{
    background: rgba($color-system-fail, 0.1);
    border-color: $color-system-fail;

    .message {
      color: $color-system-fail;
    }

    .close-icon {
      display: none;
    }

    .wait-icon{
      display: none;
    }
  }

  &.warning{
    background: rgba(255,193,7, 0.1);
    border-color: #FFC107;

    .message {
      color: #FFC107;
    }

    .close-icon {
      display: none;
    }

    .wait-icon{
      display: none;
    }
  }

  &__holder{
    margin: to-rem(11px) 0;
  }

  .wait-icon{
    margin: 0 to-rem(10px);
    vertical-align: middle;
  }

  .message{
    color: $originate-blue;
    font-size: to-rem(15px);
    font-weight: $font-semibold;
  }

  .close-icon{
    float: right;
    margin-right: to-rem(18px);
  }
}