@import "../../stylesheets/modules/modules";

.blacklist-user {

}

.modal input {
      height: auto;
      padding: 1.2rem 1.6rem;
}
