@import "../../stylesheets/modules/modules";


.form_card {
  width: 100%;
  max-width: to-rem(1100px);
  margin: 0 auto;
  margin-bottom: to-rem(40px);

  .table_row {
    height: to-rem(60px);

    .action_button {
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .button {
        height: 40px;
      }
    }
  }

}

