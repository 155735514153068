@import "../../../stylesheets/modules/modules";


.header-profile{
  margin-left: auto;

  &__name{
    cursor: pointer;
    text-transform: capitalize;

    .icon{
      margin-left: to-rem(5px);
    }
  }

  &__dropdown {
    box-shadow: 3px 4px 24px rgba(0, 0, 0, 0.15);
    background: rgba(255, 255, 255, 0.9);
    margin-top: to-rem(-15px);

    > * {
      width: 100%;
      padding: to-rem(10px) to-rem(15px);
      background: $color-white;
      border: 1px solid rgba(201, 206, 217, 0.5);
      box-sizing: border-box;
      font-weight: 600;
      font-size: 14px;
      color: $text-color;

      &:hover {
        cursor: pointer;
        background: $blue-f1f;
      }

      svg {
        margin-right: to-rem(10px);
      }
    }
  }

  .dd-wrapper{
    color: $color-black;
  }

  .dropdown{
    background: transparent;
    box-shadow: none;
    margin: 0 0 0 to-rem(-85px);

    .button {
      font-weight: $font-bold;
      margin-top: to-rem(-10px);
      padding: to-rem(6px) to-rem(17px);

      .logout {
        margin-left: to-rem(10px);
      }

      svg {
        margin-bottom: 2px;
        vertical-align: text-bottom;
      }
    }
  }

  svg{
    fill: $color-black;
  }
}
