@import "../../../stylesheets/modules/modules";

select{
  appearance: none;
  background: $color-background-grey url("../../../static/icons/caret-down.svg") center right to-rem(15px) no-repeat;
  border: 1px solid $border-color;
  border-radius: to-rem(4px);
  box-shadow: none;
  box-sizing: border-box;
  display: block;
  line-height: to-rem(24px);
  outline: none;
  padding: to-rem(12px) to-rem(32px) to-rem(12px) to-rem(16px);
  width: 100%;

  &[disabled] {
    background: rgba(242, 242, 242, 0.8) no-repeat;
    cursor: not-allowed;
  }
}

div {
  .wrapper {

    .input_description {
      display: inline-block;
      margin: to-rem(10px) 0 0 to-rem(10px);
    }

    .input_warning {
      color: $red-c12;
      display: inline-block;
      font-size: 12px;
      font-weight: $font-semibold;
      margin-top: to-rem(14px);
    }
  }
}

.select{
  @extend select;

  &_label{
    display: block;
    font-size: to-rem(14px);
    font-weight: $font-semibold;
    line-height: to-rem(16px);
    margin: 0 0 to-rem(8px);
  }

  &_group{
    display: block;
    margin: 0 0 to-rem(20px);
  }
}