@import "../../stylesheets/modules/modules";

.blacklisted-user {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-bottom: 10px;
  position: relative;

  .blacklisted-badge {
    border: 2px solid $color-system-pending;
    border-radius: 4px;
    color: $color-system-pending;
    font-weight: $font-semibold;
    padding: 0 16px;

    &.blacklisted__internal {
      border-color: $red-c12;
      color: $red-c12;
    }
  }

  &-tooltip {
    display: flex;
    position: relative;
    text-align: left;

    a {
      text-decoration: underline;
    }

    &:hover .top {
      visibility: visible;
      opacity: 1;
    }

    .top {
      width: 300px;
      top: -5px;
      left: 50%;
      transform: translate(-70%, -100%);
      padding: 10px 20px;
      color: #ffffff;
      background-color: $blue;
      font-weight: $font-semibold;
      line-height: 20px;
      font-size: 13px;
      border-radius: 8px;
      position: absolute;
      z-index: 99999999;
      box-sizing: border-box;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s;

      i {
        position: absolute;
        top: 100%;
        left: 70%;
        margin-left: -8px;
        width: 16px;
        height: 8px;
        overflow: hidden;

        &::after {
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          background-color: $blue;
        }
      }
    }
  }
}
