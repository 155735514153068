@import '../../stylesheets/modules/modules';

.show-policy-popup {
  .range_from {
    margin-bottom: to-rem(15px);

    ::after {
      background: $color-white url('../../static/icons/arrow-right.svg') center
        to-rem(15px) no-repeat;
    }

    :first-child {
      padding-right: to-rem(5px);
      font-weight: 600;
    }

    :nth-child(2) {
      font-size: to-rem(15px);
      font-weight: 400;
    }
  }

  .sections {
    background: $color-white;
    border: 1px solid $color-off-white;
    box-sizing: border-box;
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.02);
    border-radius: 4px;
    padding: to-rem(10px) to-rem(15px);
    margin: to-rem(5px) to-rem(15px) to-rem(15px);

    .listing {
      margin-bottom: to-rem(2px);

      :last-child {
        border: none;
      }
    }
  }
}

.complete-review-popup {
  .data-card {
    border-radius: to-rem(10px);

    &_title {
      text-align: center;
    }

    &_content {
      .textarea__group {
        .textarea {
          &__label {
            font-weight: $font-semibold;
          }
        }
      }
    }
  }

  .text-area__group {
    .text-area {
      &__label {
        color: red;
      }
    }
  }

  .optional {
    color: $grey-8a8;
    float: right;
    font-size: to-rem(12px);
  }
}

.review {
  background: $originate-blue;
  color: $color-white;
}

.revert {
  background: $originate-blue;
  color: $color-white;
}

.disburse,
.approve {
  background-color: $green-27a;
  border: 1px solid $color-green;
  color: $color-white;
}

.table_line {
  height: 52px;
  width: 100%;
}

.offline-repayment-table {
  ul {
    li {
      span {
        text-align: left;
      }
    }
  }
}

.view-portfolio-btn {
  text-align: left;

  a {
    background: rgba($originate-blue, 0.15);
    border-radius: 20px;
    color: $originate-blue;
    display: inline-block;
    font-weight: $font-regular;
    font-size: to-rem(14px);
    line-height: 15px;
    margin: 0 auto;
    padding: 5px 15px;
    text-align: center;
  }

  width: 100%;
}

.buttons {
  margin-top: 8px;
  .button {
    height: 39px;
    width: 132px;
  }
  .button.secondary {
    margin-right: 12px;
  }
}

.right-text {
  .bank-statement {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .icon {
      margin-right: 4px;
    }
  }
}

.rule-breakdown-popup {
  .modal__holder {
    max-width: 800px;
  }

  .close-icon {
    width: 30px;
  }
  .decision-container {
    .rc-collapse {
      background: none;
    }

    .rc-collapse > .rc-collapse-item > .rc-collapse-header {
      color: $originate-blue;
      font-size: to-rem(16px);
      font-weight: $font-semibold;
      line-height: to-rem(30px);
      padding: to-rem(10px) to-rem(15px);
    }

    .rc-collapse-content-box {
      .rule-decision {
        margin: 0 0 to-rem(30px);

        h6 {
          margin: 0 0 to-rem(10px);
        }

        &__result {
          display: flex;
          margin-right: to-rem(10px);

          &-text {
            line-height: 20px;
          }
        }

        &__item {
          align-items: center;
          display: flex;
          list-style: none;
          margin: 0 0 to-rem(15px);

          &-holder {
            padding: 0 0 0 to-rem(10px);
          }
        }
      }
    }
  }
}

.edit-application {
  float: right;
  position: relative;
  bottom: to-rem(66px);
  left: 15px;

  .button {
    font-weight: $font-bold;
  }
}

.repayment-double-buttons {
  display: block;
  margin-top: to-rem(65px);

  .cancel {
    float: left;
    padding: to-rem(10px) to-rem(45px);
  }

  .save {
    float: right;
    padding: to-rem(10px) to-rem(45px);
  }
}

.no-policy-alert {
  padding: 15px 40px 0;
  margin: 0 0 -25px;
}

.history-timeline {
  .loan-history-search {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: to-rem(10px);

    .input_group {
      width: 90%;
      margin-bottom: to-rem(20px);
      padding: to-rem(6px) 0;
    }

    .button {
      margin-top: to-rem(6px);
      padding: to-rem(14px) to-rem(40px);
    }
  }
  .data-card {
    .timeline-search {
      border-width: 0.4rem 0 0 0;
      border-color: $color-off-white;
      border-style: solid;
    }
    .timeline {
      .timeline-body {
        margin-left: 30px;
        position: relative;
        &::after {
          content: '';
          height: calc(100% - 32px);
          width: 1px;
          background-color: $originate-blue;
          position: absolute;
          left: 5px;
          top: 0;
        }
        .timeline-item {
          ul li {
            list-style: none;
            position: relative;
            &::after {
              content: '';
              height: 15px;
              width: 15px;
              border-radius: 50%;
              border: 1px solid $originate-blue;
              background-color: $color-white;
              position: absolute;
              left: -43px;
              top: 0;
              z-index: 2;
            }
          }
          .timeline-content {
            p {
              line-height: 17px;
            }
            .history_info {
              display: flex;
              font-weight: 600;
              font-size: 12px;
              line-height: 15px;
              margin-top: -25px;
              opacity: 0.6;
              .info {
                margin-right: 10px;
                .title {
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.transactionPatternData {
  padding-bottom: 10px;
  text-align: right;
  width: 70%;
}

.modelResCont {
  li {
    list-style: none;
  }
}
.modelRiskRes {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.modelResData {
  padding-bottom: 10px;
  width: 100%;
}

.modelResData__table {
  margin-top: 10px;
  width: 100%;

  tbody {
    border: none;
    width: 100%;
  }
  td {
    text-align: left;
  }
  th {
    padding-top: 10px;
  }
}

div.property-details {
  padding: 6rem 5.2rem;

  div.table {
    div {
      &.table__body {
        ul {
          li {
            &:first-child {
              border-width: 0.4rem 0 0 0;
              border-color: $color-off-white;
              border-style: solid;
            }

            span {
              font-size: to-rem(14px);

              &.left-text {
                text-align: left;
                text-transform: capitalize;
              }

              &.right-text {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}

.offer_status {
  border-radius: 1rem;
  color: $color-white;
  display: inline-block;
  font-size: 1.25rem;
  font-weight: $font-semibold;
  padding: 0 1rem;
  text-transform: capitalize;

  &_pending {
    background: $color-system-pending;
  }

  &_completed {
    background: $color-system-success;
  }

  &_declined {
    background: $color-system-fail;
  }
}

.processingfee-radios {
  .new-radio {
    margin-left: -12px;
    span {
      padding-left: 40px;
    }
  }

  .input {
    padding-left: 65px;
  }

  .processingfee-aside {
    border-right: 1px solid rgba(6, 25, 47, 0.21);
    text-align: center;
    padding: 10px 0;
    width: 56px;
    float: left;
    margin-top: -65px;
  }
}

.customerEligibilityWrapper {
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid #c9ced9;
  border-left: 1px solid #c9ced9;
  border-right: 1px solid #c9ced9;
}

.customerEligibilityContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: 25px;
  background-color: rgba(165, 173, 182, 0.1);
  border-bottom: 1px solid #c9ced9;
  padding: 13px 5px;
}

.customerEligibilityContent_item {
  @extend .customerEligibilityContent;
  padding: 0 8px;
  width: 100%;
  margin: 0 auto;
  background: none;

  .amountSpan {
    font-size: 20px;
  }

  .customerGraduatedUnpaidAmount {
    background-color: rgba(174, 64, 54, 0.07);
    border-radius: 5px;
    border: 2px solid #c1292e;
    color: #c1292e;
    font-size: 18px;
    text-align: center;
    padding: 8px 15px;
    width: 100px;
  }

  .customerGraduatedPaidAmount {
    @extend .customerGraduatedUnpaidAmount;
    background-color: rgba(244, 255, 249, 0.5);
    border: 2px solid #27ae60;
    color: #27ae60;
  }
}

.updateLoanAmountWrapper {
  .new-radio {
    margin-left: -12px;
    margin-bottom: 15px;
    width: 100%;
    span {
      padding-left: 40px;
    }
  }

  .input {
    padding-left: 85px;
    margin-top: -5px;
    margin-left: 20px;
    width: 90%;
  }

  .loanAmount-aside {
    border-right: 1px solid rgba(6, 25, 47, 0.21);
    text-align: center;
    padding: 10px 35px;
    width: 86px;
    float: left;
    margin-top: -65px;
  }
}

.decide-analysis-csv {
  padding: 30px;

  span {
    margin-left: 15px;
  }
}

.decide-analysis-span {
  background-color: #f8f9f9;
  font-size: 25px;
  font-weight: $font-bold;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
