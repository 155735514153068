@import '../../stylesheets/modules/modules';

.loan_rules_wrapper {
	.flex-container {
		.input_group {
			margin-right: to-rem(25px);
			display: flex;
		}

		.form-desc {
			margin-right: to-rem(40px);
		}

		.form-fields {
			position: relative;

			.gap-bottom-20 {
				margin-bottom: to-rem(20px);
			}

			.multi-select div {
				width: auto;
			}

			.controlled-multi-select {
				width: 100%;
				max-width: 566px;
			}
		}

		.checkbox-group {
			padding-left: 0.1rem;
			display: flex;
			flex-wrap: wrap;

			* {
				margin-bottom: to-rem(5px);

				.checkbox__label {
					width: 100%;
					min-width: 160px;
				}
			}
		}

		.radio-group {
			display: flex;
			flex-direction: row;

			.new-radio {
				display: flex;
				flex-direction: row;
			}

			* {
				margin-bottom: to-rem(-4px);
				margin-left: to-rem(-5px);
			}
		}

		.custom-rule-error {
			margin-bottom: to-rem(20px);
		}

		.wrapper {
			select {
				width: to-rem(250px);
			}

			.stepType {
				width: 100%;
			}
		}
	}

	.form-fields {
		position: relative;

		&.toggle-field-section {
			> .card {
				margin-top: to-rem(20px);
			}

			.card .card-content {
				max-width: 99%;
				display: inline;

				.line-item {
					position: relative;
					margin: 0 -10px 10px;

					&__form {
						padding: 10px;
					}

					.remove-step {
						align-items: center;
						display: flex;
						margin: 10px 0;
						height: calc(100% - 20px);
						transition: 0.3s;

							&:hover {
								background: $color-status-rejected;
								height: 100%;
								margin: 0;

								svg * {
									fill: $color-white;
								}

								& ~ .line-item__form {
								background: rgba($color-system-fail, 0.05);
								transition: 0.3s;
							}
						}
					}
				}

				.flex-container {
					align-items: baseline;

					.card {
						margin-bottom: 0;
					}

					.input_group {
						margin-bottom: 0;
					}

					&.add-new-step {
						align-items: center;
						border-radius: 5px;
						display: inline-flex;
						font-weight: $font-bold;
						padding: 5px 10px 5px 5px;
						transition: 0.3s;

						&:hover {
							background: rgba($originate-blue, 0.15);
							color: $originate-blue;
						}

						svg {
							margin-right: 5px;
						}
					}
				}
			}
		}

		.remove-step {
			cursor: pointer;
			position: absolute;
			right: 0;
			z-index: 9;
		}

		.add-more {
			margin-bottom: to-rem(20px);

			.add-new-step {
				align-items: flex-start;
				cursor: pointer;

				svg {
					height: to-rem(19px);
				}
			}
		}
	}

	.container_wrapper {
		width: 100%;
		margin: 0 auto;
		max-width: to-rem(950px);
		margin-bottom: to-rem(30px);
	}

	.action_button {
		text-align: right;

		button,
		a {
			width: 100%;
			font-size: 12px;
			height: to-rem(40px);
			max-width: to-rem(194px);

			&.edit_button {
				margin-right: to-rem(24px);
			}
		}

		@include sm-phone {
			align-items: center;
			flex-direction: column;
			max-width: $mobile-width;

			button:first-of-type,
			a:first-of-type {
				margin-bottom: to-rem(20px);
			}
		}
	}

	.table {
		&__head {
			span {
				text-align: left;
			}
		}

		&__body {
			ul li span {
				border-bottom: none;
			}

			.table_line {
				align-items: center;
				border-bottom: 1px solid #f4f5f6;

				a {
					span {
						text-align: left;

						&.ruleSet_policies {
							padding: to-rem(0) to-rem(3px);
						}

						&.checked-width {
							> span {
								width: 100%;
								max-width: to-rem(71px);
								text-align: center;
							}
						}
					}
				}

				.action_button {
					text-align: right;

					button {
						height: to-rem(30.1px);
						font-size: 12px;
						max-width: to-rem(100px);
						padding: to-rem(5px) to-rem(25px);

						&.edit_button {
							margin-right: to-rem(24px);
						}
					}
				}
			}
		}
	}
}
