@import "../../../stylesheets/modules/modules";

textarea{
  background: $color-white;
  border: 1px solid $border-color;
  border-radius: to-rem(4px);
  box-shadow: none;
  box-sizing: border-box;
  display: block;
  line-height: to-rem(24px);
  height: auto;
  outline: none;
  padding: to-rem(13px) to-rem(16px);
  resize: none;
  width: 100%;
}

.textarea{
  @extend textarea;

  &__label{
    display: block;
    font-size: to-rem(14px);
    font-weight: $font-semibold;
    line-height: to-rem(16px);
    margin: 0 0 to-rem(8px);
  }

  &__group{
    display: block;
    margin: 0 0 to-rem(20px);
  }
}