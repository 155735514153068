@import "../../../stylesheets/modules/modules";

.new-checkbox {
  box-sizing: border-box;
  color: $text-color;
  display: flex;
  line-height: to-rem(24px);
  margin-bottom: to-rem(20px);
  outline: none;
  width: 100%;


  .new-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input{
    appearance: none;
    background: $color-white;
    border: 1px solid $originate-blue;
    border-radius: 0;
    display: inline-block;
    height: 18px;
    position: relative;
    transition: 0.3s;
    width: 21px;
    cursor: pointer;

    &:checked{
      border-color: $originate-blue;
      background:$color-white;

      &::after{
        border-color: $color-white;
        content: "";
        display: block;
        position: absolute;
        left: 2px;
        top: 2px;
        width: 12px;
        height: 12px;
        background-color: $originate-blue;
        transform: rotate(-90deg);
      }

    }
  }
  &__label{
    margin: to-rem(-2px) 0 0 to-rem(15px);
    width: 100%;
  }

  &__disabled{
    opacity: 0.4;
  }

  .processing-fee-checkbox {
    padding: 2px;
    border-radius: 3px;
  }
}
