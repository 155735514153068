@import "../../stylesheets/modules/modules";

.toggle {
  position: relative;
  display: block;
  width: to-rem(42px);
  height: to-rem(24px);

  input {
    &:checked + .slider {
      background-color: $color-green;

      &::before {
        transform: translateX(16px);
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $color-green;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $grey-f1f;
    transition: .4s;
    border-radius: to-rem(32px);

    &::before {
      position: absolute;
      content: "";
      height: to-rem(18px);
      width: to-rem(18px);
      left: 4px;
      bottom: 3px;
      background-color: white;
      transition: .4s;
      border-radius: 50%;
    }
  }
}