@import '../../stylesheets/modules/modules';

.table {
  width: 100%;
  table-layout: fixed;

  &__head {
    display: flex;
    flex-direction: row;
    width: 100%;

    span {
      border-bottom: 1px solid $color-background-grey;
      color: $dark-blue;
      flex: 1;
      font-size: to-rem(14px);
      font-weight: $font-bold;
      line-height: to-rem(17px);
      padding: to-rem(12px);
      text-align: center;
      white-space: nowrap;
    }

    &.align-left {
      span {
        text-align: left;
      }
    }
  }

  &__body {

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        position: relative;

        span {
          flex: 1;
          border-bottom: 1px solid $color-background-grey;
          font-size: to-rem(14px);
          line-height: to-rem(17px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
          padding: 15px 15px 15px 9px;

          a {
            color: $originate-blue;
          }
          .status {
            background: $color-grey;
            border-radius: to-rem(4px);
            color: $color-white;
            display: block;
            font-size: to-rem(12px);
            max-width: to-rem(42px);
            padding: to-rem(2px) to-rem(8px);
            width: 100%;

            &.active,
            &.approved,
            &.disbursed {
              background: $color-status-success;
            }

            &.denied,
            &.overdue,
            &.declined,
            &.deleted {
              background: $color-status-rejected;
            }

            &.closed,
            &.disabled {
              background: $color-grey;
            }

            &.under-review,
            &.under_review,
            &.draft {
              background: $color-status-under-review;
            }

            &.pending,
            &.pending_disbursement,
            &.pending_disbursment {
              background: $color-status-pending;
            }

            &.offer_letter_sent,
            &.awaiting_feedback {
              background: $color-status-awaiting-feedback;
              color: $originate-blue;
            }

            &.disbursing {
              background: $color-status-disbursing;
            }

            &.abandoned {
              background: $color-status-abandoned;
            }
          }
          .status-text {
            &.pending_activation {
              color: $color-status-under-review;
            }

            &.activated {
              color: $color-system-success;
            }

            &.deactivated {
              color: $color-status-rejected;
            }
          }
          .tx-column {
            color: $text-color;
          }
        }

        &:hover {
          background: #f8f8f8;
          width: 100%;
        }
      }
    }

    &.align-left {
      span {
        text-align: left;
      }
    }

    a {
      display: contents;

      &:hover {
        display: contents;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    width: 100%;

    span {
      border-bottom: 1px solid $color-background-grey;
      color: $dark-blue;
      flex: 1;
      font-size: to-rem(14px);
      font-weight: $font-bold;
      line-height: to-rem(17px);
      padding: to-rem(12px);
      text-align: center;
      white-space: nowrap;
    }
  }

  .first-td {
    border: to-rem(0.5px) solid rgba(201, 206, 217, 0.95);
    border-radius: to-rem(22px);
    box-sizing: border-box;
    display: inline;
    font-weight: $font-semibold;
    margin-left: to-rem(-15px);
    mix-blend-mode: normal;
    padding: to-rem(8px) to-rem(15px);
  }
  .tx-column {
    margin-left: to-rem(-15px);
    display: inline;
  }

  .status-table {
    display: inline-block;
    flex-grow: 0.4;
  }

  .tooltip-title {
    display: inline-block;

    &:hover {
      cursor: help;
      content: '';
    }

    &:hover:after {
      background: #6d7381;
      background: rgba(0, 0, 0, 0.8);
      border-radius: to-rem(5px);
      top: 74%;
      color: white;
      content: attr(data-title);
      font-size: to-rem(11px);
      left: 59%;
      padding: to-rem(5px) to-rem(15px);
      position: absolute;
      white-space: normal;
      z-index: 98;
    }

    &:hover:before {
      border: solid;
      border-color: #333 transparent;
      border-width: 0 6px 6px 6px;
      bottom: 27%;
      content: '';
      left: 64%;
      position: absolute;
      z-index: 99;
    }
  }
  .edit {
    color: $originate-blue !important;
  }
}
