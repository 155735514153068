@import '../../stylesheets/modules/modules';

.modal {
  height: 100vh;
  left: 0;
  padding: 0 15px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99;
  transform: translateY(-200%);

  &.active {
    transform: translateY(0%);
  }

  &__holder {
    margin: 0 auto;
    max-height: 90vh;
    max-width: to-rem(550px);
    overflow: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  &__content {
    background: $color-white;
    box-shadow: to-rem(1px) to-rem(3px) to-rem(8px) rgba(0, 0, 0, 0.02);
    padding: 32px;

    .close-icon {
      position: absolute;
      top: 0;
      right: 0;
      margin: 22px 30px;

      &:hover {
        cursor: pointer;
      }
    }

    &-top {
      font-weight: $font-semibold;
      line-height: to-rem(26px);
      margin: 0 0 10px;
      text-align: center;

      svg {
        float: right;
        margin-top: to-rem(-7px);
      }
    }

    .actions {
      margin-top: to-rem(20px);
    }

    &-title {
      display: inline-block;
      font-size: to-rem(18px);
      margin-top: to-rem(15px);
    }
  }

  &:before {
    background: rgba($color-black, 0.4);
    bottom: 0;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  select {
    background: $grey-fcf url('../../static/icons/caret-down.svg') center right
      to-rem(15px) no-repeat;
    border: 1px solid rgba(201, 206, 217, 0.95);
    box-sizing: border-box;
    border-radius: 2px;
    height: to-rem(45px);
    margin-top: to-rem(11px);
    outline: none;
    padding: 0 to-rem(15px);
    width: 100%;

    &::placeholder {
      color: $originate-deep-blue;
      opacity: 0.5;
    }
  }

  input {
    background: $grey-fcf;
    border: 1px solid rgba(201, 206, 217, 0.95);
    box-sizing: border-box;
    border-radius: 2px;
    height: to-rem(45px);
    margin-top: to-rem(11px);
    outline: none;
    padding: 0 to-rem(15px);
    width: 100%;

    &::placeholder {
      color: $originate-deep-blue;
      opacity: 0.5;
    }
  }

  input {
    &[disabled] {
      background: rgba(242, 242, 242, 0.8) no-repeat;
      cursor: not-allowed;
    }
  }
}
