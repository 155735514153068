@import "../../stylesheets/modules/modules";

.customerdetails-sidebar{
  border-right: 1px solid rgba(201, 206, 217, 0.95);
  height: calc(100% - 150px);
  left: 0;
  overflow-y: scroll;
  position: fixed;
  top: to-rem(150px);
  width: to-rem(375px);
  z-index: 1;

  .customer-info{
    padding: 0 0 to-rem(50px);
    text-align: center;

    .cover-image{
      background: url("../../static/icons/cover-image.png") center no-repeat;
      background-size: cover;
      display: block;
      height: to-rem(120px);
    }

    .avatar{
      background: #FFFFFF;
      border: 3px solid #F5F5F5;
      border-radius: 50%;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
      box-sizing: content-box;
      font-size: 24px;
      font-weight: $font-semibold;
      height: to-rem(96px);
      line-height: to-rem(96px);
      margin: to-rem(-51px) auto to-rem(24px);
      position: relative;
      text-align: center;
      width: to-rem(96px);
    }

    .avatar-img{
      background: #fff;
      border-radius: 50%;
      box-shadow: 0 1px 4px rgba(0,0,0,.05);
      box-sizing: content-box;
      font-size: 24px;
      font-weight: 600;
      height: 9.6rem;
      line-height: 9.6rem;
      margin: -5.1rem auto 2.4rem;
      position: relative;
      text-align: center;
      width: 9.6rem;
    }

    h3{
      margin: 0 0 to-rem(8px);
      padding: 0;
    }

    h5{
      font-size: to-rem(16px);
      font-weight: $font-regular;
      line-height: to-rem(27px);
      margin: 0;
      padding: 0;

      .score{
        font-size: to-rem(22px);
        font-weight: $font-bold;
        line-height: 27px;
        margin-left: to-rem(10px);

        &.above{
          color: $color-green;
        }

        &.below{
          color: $color-system-fail;
        }

        &-tooltip{
          background: url("../../static/icons/tooltip-icon.svg") center no-repeat;
          display: inline-block;
          height: to-rem(10px);
          margin-left: 5px;
          width: to-rem(10px);
        }
      }
    }
  }

  .detail-section{
    h6{
      border-bottom: 4px solid #F2F2F2;
      font-size: to-rem(12px);
      line-height: to-rem(15px);
      margin: 0;
      padding: to-rem(5px) to-rem(16px);
    }

    > ul{
      list-style: none;
      margin: 0 0 to-rem(35px);
      padding: 0;

      > li{
        align-items: center;
        border-bottom: 2px solid #F2F2F2;
        display: flex;
        height: to-rem(48px);
        padding: 0 to-rem(16px);

        span, .detail-section__label{
          font-size: to-rem(14px);
          min-width: 100px;
        }

        span:last-of-type{
          font-weight: $font-semibold;
          margin-left: auto;
          text-align: right;
        }

        &.user-roles-list {
          height: auto;
          min-height: to-rem(48px);

          aside {
            list-style: none;
            flex: 1;

            &.right {
              text-align: right;

              i {
                float: right;
              }
            }

            i {
              background: white;
              font-style: normal;
              font-weight: $font-semibold;
              padding: 0 7.5px;
              margin-bottom: 3px;
              margin-left: 3px;
              display: inline-block;
            }
          }
        }
      }
    }

    .view-customerdetails{
        font-size: to-rem(12px);
        font-weight: $font-bold;
        margin: to-rem(-33px) 0 to-rem(17px) to-rem(74px);
        padding: to-rem(9px) to-rem(55px);
    }
  }
}
