@import '../../stylesheets/modules/modules';

.merchant-upload {
  width: 100%;
  .modal {
    &__content {
      overflow: hidden;
      width: 100%;
      text-align: center;
      word-break: break-all;
      .file-name {
        color: $originate-blue;
        font-weight: 600;
      }
    }
    &__holder {
      width: fit-content;
    }

    p {
      font-size: 16px;

      .file-name {
        margin-left: 0.3rem;
        font-family: sans-serif;
      }
    }

    .button-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.file-upload-button {
  display: flex;
  flex-direction: column;

  .button-container {
    display: flex;
    align-items: center;
  }

  .file-name {
    margin-left: 10px;
    font-size: 14px;
    color: #555;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 180px; /* Set a max-width to control the truncation */
  }

  .error-text {
    font-size: 12px;
    color: red;
  }
}

.upload-button {
  border: 1px solid #d6ddeb4d;
  background-color: #d6ddeb4d;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}
