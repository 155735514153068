@import '../../stylesheets/modules/modules';

.manual-repayments {
  .checked-width {
    margin-left:50px ;
    padding-right: 120px;
    .status {
      max-width: to-rem(122px);
      padding: to-rem(7px) to-rem(20px);
      position: absolute;
      left: 85.5%;
      bottom: 19.46%;
    }
  }

}
