@import "../../../stylesheets/modules/modules";

.radio{
  cursor: pointer;
  display: inline-block;
  margin: 0 to-rem(15px) to-rem(20px) 0;

  input{
    margin: 0;
    opacity: 0;
    position: absolute;
    width: 0;

    height: 0;

    &:checked + span {
      background: $color-white;
      border-color: $originate-blue;
      box-shadow: 0 to-rem(3px) to-rem(15px) rgba($originate-blue, 0.1);
      color: $text-color;

      &:before {
        border-color: $originate-blue;
        background: $originate-blue;
      }

      &:after {
        background: $color-white;
      }
    }
  }

  span {
    border: 1px solid $border-color;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    font-weight: $font-regular;
    line-height: to-rem(20px);
    margin: 0;
    outline: none;
    padding: to-rem(10px) to-rem(10px) to-rem(10px) to-rem(40px);
    position: relative;
    transition: all 0.2s ease-in;
    width: 100%;

    &.borderless{
      border: none;
    }

    &:before {
      background: transparent;
      border: 1px solid $border-color;
      border-radius: 100%;
      box-sizing: border-box;
      box-shadow: none;
      content: "";
      left: to-rem(10px);
      top: to-rem(10px);
      height: to-rem(20px);
      position: absolute;
      width: to-rem(20px);
    }

    &:after {
      border-radius: 100%;
      box-sizing: border-box;
      content: "";
      height: to-rem(8px);
      left: to-rem(16px);
      position: absolute;
      top: to-rem(16px);
      width: to-rem(8px);
    }
  }
}
