@import "../../../stylesheets/modules/modules";

.form-group {
  display: grid;

  &.center{
    justify-content: center;
  }

  &.margin{
    margin: 0 0 to-rem(30px);
  }

  &.two-grid{
    grid-template-columns: 1fr 1fr;
    grid-gap: to-rem(30px);
  }

  &.three-grid{
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: to-rem(30px);
  }
}
