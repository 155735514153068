@import '../../stylesheets/modules/modules';

.button {
  background: $color-primary;
  border: 1px solid $originate-blue;
  border-radius: 4px;
  box-shadow: none;
  color: $originate-blue;
  cursor: pointer;
  display: inline-block;
  font-weight: $font-semibold;
  line-height: to-rem(20px);
  padding: to-rem(10px) to-rem(25px);
  transition: 0.3s;
  outline: none;
  overflow-anchor: none;

  &:disabled,
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.secondary {
    background: $originate-blue;
    color: $color-white;
  }

  &.right {
    float: right;
  }

  &.center {
    display: block;
    margin: 0 auto;
  }

  &.block {
    display: block;
    width: 100%;
  }

  &.span {
	padding: 10px 45px;
  }
  
  &.edit-processing-fee {
    display: inline-block;
    border: none;
    background: none;
  }

  &.link {
    border: none;
    padding: 0;
    font-weight: $font-regular;

    &-underline {
      background: none;
      border: none;
      text-decoration: underline;
    }
  }

  &.btn-small {
    padding: to-rem(4px) to-rem(10px);
    font-size: 11px;
  }

  &.btn-medium {
    padding: to-rem(6px) to-rem(16px);
    font-size: 14px;
  }

  &.green {
    background-color: $green-27a;
    border: 1px solid $color-green;
    color: $color-white;
  }

  &.grey {
    border: 1px solid #C9C9C9;
    background: transparent;
    color: $dark-grey;
  }

  &.blue {
    background-color: $originate-blue;
    border: 1px solid $originate-blue;
    color: $color-white;
  }

  &.red {
    background-color: rgba(244, 10, 10, 0.03);
    border: 1px solid $color-status-rejected;
    color: $color-status-rejected;

    &.solid {
      background-color: $red-c12;
      border-color: $red-c12;
      color: $color-white;
    }
  }

  &.loading {
    .button-text {
      opacity: 0;
    }

    &:after {
      animation: spin 0.6s linear infinite;
      visibility: visible;
    }
  }
}
