@import "../../stylesheets/modules/modules";

.analytics-overview{

  &__header{
    padding-bottom: to-rem(10px);

    h5{
      color: $text-color;
      display: inline-block;
      margin-bottom: to-rem(15px);
      line-height: to-rem(18px);
    }

    .dd-wrapper{
      color: $text-color;
    }

    .date{
      font-size: to-rem(18px);
      margin-right: to-rem(7px);
      opacity: 0.6;
    }

  }

  &__content{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: to-rem(70px);

    h3{
      margin: to-rem(20px) 0 to-rem(7px) 0;
      font-weight: $font-regular;
    }

    h5{
      color: $dark-grey;
      font-size: to-rem(12px);
      line-height: to-rem(12px);
    }
  }
}