@import "../../stylesheets/modules/modules";

.customer-summary{
  background: $blue;
  border: to-rem(1px) solid rgba(68, 83, 99, 0.02);
  border-radius: to-rem(6px);
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04);
  padding: to-rem(20px) 0 to-rem(20px) to-rem(30px);

  &__header{
    padding-bottom: to-rem(15px);

    h5{
      color: $color-white;
      display: inline-block;
      font-size: to-rem(12px);
      line-height: to-rem(15px);
    }
  }

  &__content{
    display: grid;
    grid-gap: to-rem(150px);
    padding-bottom: to-rem(10px);

    &.two-grid{
      grid-template-columns: repeat(2, 1fr);
    }

    &.three-grid{
      grid-template-columns: repeat(3, 1fr);
    }

    &.four-grid{
      grid-template-columns: repeat(4, 1fr);
      grid-gap: to-rem(120px);
    }

    h5{
      color: rgba(255, 255, 255, 0.8);
      font-weight: normal;
      margin-bottom: to-rem(9px);
      text-transform: capitalize;
    }

    h3{
      color: $color-white;
    }

    .arrow-right-up::after{
      content: url("../../static/icons/arrow-right-up.svg");
      margin-left: to-rem(6px);
      }

    .arrow-right-down::after{
      content: url("../../static/icons/arrow-right-down.svg");
      margin-left: to-rem(6px);
    }

    .arrow-up::after{
      content: url("../../static/icons/arrow-up.svg");
      margin-left: to-rem(6px);

    }
  }
}