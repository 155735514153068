@import "../../stylesheets/modules/modules";

.loader-container{
  background: rgba($color-white, 0.88);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;

  .loader-holder{
    display: block;
    position: relative;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
  }

  .loader{
    animation: loading 1s linear infinite;
    background: none;
    border: to-rem(10px) solid rgba($color-background-grey, 0.8);
    border-top-color: $originate-blue;
    border-radius: 100%;
    display: block;
    margin: 0 auto to-rem(30px);
    height: to-rem(75px);
    width: to-rem(75px);

    &-text{
      border-radius: to-rem(5px);
      display: inline-block;
      font-size: to-rem(16px);
      font-weight: $font-semibold;
      padding: to-rem(15px) to-rem(20px);
    }
  }

  @keyframes loading {
    0% {transform: rotate(90deg);}
    100% {transform: rotate(450deg);}
  }
}