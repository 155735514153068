@import "../../stylesheets/modules/modules";

.empty-screen{
  text-align: center;
  padding: to-rem(150px) 0;

  &.no-padding{
    padding: 0;
  }

  &__message{
    display: block;
  }
}