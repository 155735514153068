@import "../../stylesheets/modules/modules";

.form-fields {
  position: relative;

  .gap-bottom-20 {
    margin-bottom: to-rem(20px);
  }

  .multi-selection div {
    padding: 0;
    width: auto;
  }

  .controlled-multi-selection {
    width: 100%;
    max-width: 566px;
  }

  .add-step {
    color: $originate-blue;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.1px;
    max-width: to-rem(268px);
    margin: 0 auto;

    &:hover {
      cursor: pointer;
      color: $originate-blue;
    }

    svg {
      height: to-rem(19px);
      margin-right: to-rem(5px);
    }
  }
}

.checkbox__label {
  margin-bottom: 6px;
}

.checkbox-group {
  p {
    font-weight: bold;
    margin-left: -33px;
  }
}

.loan-group-modal {
  .modal__holder {
    height: 35%;

    .modal__content {
      height: 100%;
    }
  }
}

.desc-note {
  .textarea__group {
    margin: 0;
  }

  span {
    display: inline-block;
    font-weight: $font-bold;
  }
}

.add-supp-doc {
  padding-left: 214px;
}

.checkbox-flex {
  align-self: flex-start;

  &-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
}
