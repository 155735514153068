@import "../../../stylesheets/modules/modules";

.blacklist-reports {
  padding: 30px;

  &__table {
    width: 100%;

    .align-left {
      text-align: left;
    }

    .align-right {
      text-align: right;
    }

    th {
      line-height: 30px;
      padding: 12px 8px;
      text-align: center;
    }

    tr {
      border-bottom: 1px solid #f4f5f6;
    }

    td {
      padding: 12px 8px;
      text-align: center;
      width: 100px;

      &.full {
        line-height: 20px;
        width: auto;
      }
    }
  }

  &__cell {
    align-items: center;
    display: inline-flex;

    &.medium {
      width: 150px;
    }
  }

  &__buttons {
    display: flex;
    // justify-content: flex-end;
    margin-bottom: 20px;
  }
}
