@import "../../stylesheets/modules/modules";

.notification {
  background: rgba($color-black, 0.2);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;

  &_holder {
    align-items: center;
    background: $color-white;
    border: 1px solid rgba($color-black, 0.07);;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 37.5%;
    max-width: 100%;
    padding: to-rem(40px) to-rem(40px);
    position: relative;
    top: 20%;
    width: 450px;
  }

  &_title {
    font-weight: $font-bold;
    letter-spacing: 0.1px;
    line-height: to-rem(26px);
    text-align: center;
  }

  &_icon-holder {
    margin: to-rem(25px) 0;
    text-align: center;
  }

  &_text {
    font-size: to-rem(16px);
    font-weight: $font-bold;
    line-height: to-rem(24px);
    text-align: center;
  }

  @include phone {

    &_holder {
      left: 16%;
      width: to-rem(300px);
    }
  }
}
