@import "../../stylesheets/modules/modules";

.toast{
  background: #828282;
  border-radius: to-rem(4px) 0 0 to-rem(4px);
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.02);
  color: $color-white;
  padding: to-rem(11px) to-rem(25px) to-rem(11px) to-rem(25px);
  position: fixed;
  right: 20px;
  top: 20px;
  transform: translateY(-200%);
  transition: 0.3s;
  width: to-rem(345px);
  z-index: 999;

  &.active{
    transform: translateY(0%);
  }

  &.Success,
  &.green,
  &.successful{
    background: $color-system-success;
  }

  &.Failed,
  &.fail,
  &.red,
  &.failure{
    background: $color-system-fail;
  }

  &__title{
    h6{
      display: inline;
      margin: 0 to-rem(70px) 0 0;
      text-transform: none;
    }
  }

  p{
    margin: 0;
    line-height: to-rem(18px);
    white-space: pre-line;
  }

  svg{
    stroke: $color-white;
    float: right;
  }
}